import { Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AutocompleteMult from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Masks from "../../comm/masks";
import AlertAtendimentoBMP from "../../components/alertAtendimentoBMP/alertAtendimentoBMP";
import AlertBrasilMaisProdutivo from "../../components/alertBrasilMaisProdutivo/alertBrasilMaisProdutivo";
import AlertProducaoBMP from "../../components/alertProducaoBMP/alertProducaoBMP";
import "../../components/formField/formField.scss";
import SimpleModal from "../../components/modal/modal";
import Table from "../../components/table/table";
import NovoClientePage from "../cliente/clienteNovo";
import API, { newBackend } from "./../../comm/API";
import AutoComplete from "./../../components/autoComplete/autoComplete";
import FormField from "./../../components/formField/formField";
import GenericButton from "./../../components/genericButton/genericButton";
import InputSelect from "./../../components/inputselect/inputselect";
import Page from "./../../components/page/page";
import Stepper from "./../../components/stepper/stepper";
import "./atendimentoNovo.scss";
import { areaPesquisaSuggestions, areaServicoOptions, AtendimentoModel, AtendimentoPesquisaModel, PrevisaoProducaoEmRedeModel, PrevisaoReceitaModel } from "./atendimentoNovoUtil";
import { PorteClienteEnum, porteClienteReceitaMapping } from "./enum/porteClienteEnum";
import { produtoNacionalBMPEnum } from "./enum/produtoNacionalBMP";

export default class AtendimentoNovo extends Component {
  statusList = [];
  isAtendimentoBP = false;
  isAtendimentoIA = false;
  isAtendimentoIPEA = false;
  isAtendimentoLEAN28 = false;
  isAtendimentoLEAN56 = false;
  isAtendimentoMENTORIA = false;
  isAtendimentoIE = false;
  isAtendimentoIA180 = false;
  isAtendimentoDIGITAL = false;
  isAtendimentoBrasilMaisLEAN = false;
  isInovacao = false;
  isConsultoria = false;
  isMetrologia = false;
  isSte = false;
  isAccepted = false;
  isDR = false;
  isUO = false;
  controller = null;
  errors = {
    dataAceitacao: { subClass: "", error: "" },
  };
  canChangeStatus = false;
  dataAceitacao = undefined;

  ITEM_HEIGHT = 48;
  ITEM_PADDING_TOP = 8;
  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  state = {
    isAreaPesquisaMaxOptions: false,
    novoCliente: {},
    producaoTotalPrevista: 0,
    autoCompleteFontePath: "",
    atendimento: new AtendimentoModel(),
    content: [],
    parametros: {},
    tipoProduto: [],
    tipoVenda: [],
    addParceiro: null,
    previsaoReceitaList: [],
    parceiroList: [],
    chaveSI: {},
    openModal: false,
    parceiroICTAgencia: { descricao: "" },
    descricaoParceiro: "",
    errors: this.setError(),
    panelCount: 0,
    isParceiroRede: false,
    statusNotificacaoAceito: null,
    atendimentoExecutor: null,
    tipoPrevisaoReceitaList: [],
    atendimentoRede: null,
    linhaAtendimento: 3,
    openModalFonte: false,
    radioTipoFonte: "",
    radioTipoFontePagadora: "",
    radioTipoParceria: "",
    radioIsICT: "",
    radioIsReguladosIsento: "",
    recursoRegulado: null,
    valorFonteFinanceiro: 0,
    valorFonteEconomico: 0,
    fontesRecursos: [],
    openModalParceria: false,
    openModalConfirmaStatusInicial: false,
    atendimentoStatus: 2,
    descricaoProposta: "",
    dataAssinaturaContrato: "",
    nomeArquivoProposta: "",
    nomeArquivoContrato: "",
    setoresPorCnaeObjects: [],
    fontePagadora: null,
    fonteRandID: 0,
    optionModalFonte: "Adicionar",
    isfillReceitaBase: false,
    valorFinanceiro: 0,
    valorEconomico: 0,
    uploadAnexoProposta: null,
    uploadAnexoContrato: null,
    isAtendimentoBMP: false,
    emOutroAtendimentoBMP: false,
    isAtendimentoBMPSemPorte: false,
    idPorteClienteReceita: 0,
    producaoBMPConforme: false,
    porteClienteReceita: "",
  };

  linesTable = [];

  titles = ["Informações Gerais", "Descritivo", "Organização/Pessoa Atendida", "Recursos", "Produção", "Revisão"];

  linhaAtendimentoOptions = {
    1: "Consultoria",
    2: "PDI",
    3: "Metrologia",
    4: "STE",
    6: "Serviços Complementares",
  };

  titlesTabela = [
    { id: "0", canOrder: false, label: "Nome da Unidade" },
    { id: "1", canOrder: false, label: "Produção Prevista" },
    { id: "2", canOrder: false, label: "Receita Prevista" },
    { id: "3", canOrder: false, label: "" },
  ];

  titlesTabelaList = [{ id: "0", canOrder: false, label: "Nome da Unidade" }];

  titlesTabFontes = [
    { id: "0", canOrder: false, label: "Fonte de recursos" },
    { id: "1", canOrder: false, label: "Valor do recurso" },
  ];

  producaoPorPorte = {
    [PorteClienteEnum.MICRO_EMPRESA]: {
      [produtoNacionalBMPEnum.eficienciaEnergetica]: 76,
      [produtoNacionalBMPEnum.manufaturaEnxuta]: 76,
    },
    [PorteClienteEnum.PEQUENO_PORTE]: {
      [produtoNacionalBMPEnum.eficienciaEnergetica]: 106,
      [produtoNacionalBMPEnum.manufaturaEnxuta]: 106,
    },
    [PorteClienteEnum.MEDIO_PORTE]: {
      [produtoNacionalBMPEnum.eficienciaEnergetica]: 116,
      [produtoNacionalBMPEnum.manufaturaEnxuta]: 116,
    },
    [PorteClienteEnum.GRANDE_PORTE]: {
      [produtoNacionalBMPEnum.eficienciaEnergetica]: 116,
      [produtoNacionalBMPEnum.manufaturaEnxuta]: 116,
    },
    [PorteClienteEnum.DEMAIS]: {
      [produtoNacionalBMPEnum.eficienciaEnergetica]: 116,
      [produtoNacionalBMPEnum.manufaturaEnxuta]: 116,
    },
  };

  showToast = () => {};
  showLoading = () => {};
  closeLoading = () => {};
  getFunctions = (showToast, showLoading, closeLoading) => {
    if (showToast != null) this.showToast = showToast;
    if (showLoading != null) this.showLoading = showLoading;
    if (closeLoading != null) this.closeLoading = closeLoading;
  };

  componentDidMount() {
    this.closeLoading();
  }

  getAtendimento = async () => {
    if (this.props.idAtendimento) {
      this.showLoading();
      var filter = {};
      filter["atendimentoExecutor.id"] = this.props.idAtendimento;
      filter["unidadeParceira.id"] = this.props.usuarioConectado.unidade.id;
      var atendimentoCount = (await API.get(`/rest/gestao/AtendimentoRede/count`, { params: filter })).data.count;
      this.setState({ isParceiroRede: atendimentoCount > 0 });
      if (atendimentoCount > 0) {
        var filter2 = {};
        filter2["unidadeParceira.id"] = this.props.usuarioConectado.unidade.id;
        filter2["atendimentoExecutor.id"] = this.props.idAtendimento;

        this.setState({ atendimentoRede: (await API.get(`/rest/gestao/AtendimentoRede`, { params: filter2 })).data[0] });
        this.setState({ statusNotificacaoAceito: (await API.get(`/rest/gestao/StatusNotificacao`, { params: { id: 2 } })).data[0] });
        this.setState({ atendimentoExecutor: this.state.atendimentoRede.atendimentoExecutor });
        this.createRede();
        this.convertprevisaoProducaoEmRede(this.state.atendimentoExecutor.previsaoProducaoEmRedeList);
        this.closeLoading();
      } else {
        let atendimento = (await API.get(`/rest/gestao/Atendimento/${this.props.idAtendimento}`)).data;

        atendimento.setor = atendimento.setor ? atendimento.setor : { id: 0, descricao: "" };
        atendimento.atendimentoPesquisa = atendimento.atendimentoPesquisa ? atendimento.atendimentoPesquisa : new AtendimentoPesquisaModel();

        if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO)) {
          this.isAtendimentoBP = true;
        } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_INDUSTRIA_MAIS_AVANCADA)) {
          this.isAtendimentoIA = true;
        } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_IPEA)) {
          this.isAtendimentoIPEA = true;
        } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_EFICIENCIA_ENERGETICA)) {
          this.isAtendimentoIE = true;
        } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_LEAN_28)) {
          this.isAtendimentoLEAN28 = true;
        } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_LEAN_56)) {
          this.isAtendimentoLEAN56 = true;
        } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_MENTORIA)) {
          this.isAtendimentoMENTORIA = true;
        } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_CONSULTORIA_MANUFATURA_180)) {
          this.isAtendimentoIA180 = true;
        } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_MENTORIA_DIGITAL)) {
          this.isAtendimentoDIGITAL = true;
        } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_MENTORIA_LEAN)) {
          this.isAtendimentoBrasilMaisLEAN = true;
        }
        let linha = atendimento.produtoRegional.produtoNacional.produtoCategoria.produtoLinha.id;
        let fontesRecursos = atendimento.previsaoReceitaList.map((each) => {
          each.randID = Math.random();
          return each;
        });
        this.updateProdutoLinha(atendimento.produtoRegional.produtoNacional.produtoCategoria.produtoLinha);
        this.setState({ atendimento: atendimento, linhaAtendimento: linha, fontesRecursos: fontesRecursos });
        this.updateSetoresPorCnae();
        this.convertPrevisaoReceita(atendimento.previsaoReceitaList);
        this.convertprevisaoProducaoEmRede(atendimento.previsaoProducaoEmRedeList);
        this.statusAceito(atendimento);
        this.closeLoading();
      }
    }
  };

  convertprevisaoProducaoEmRede(list) {
    let prodEmRed = this.state.parceiroList;
    list.forEach((prod) => {
      if (this.state.isParceiroRede) {
        if (prod.unidade.id === this.props.usuarioConectado.unidade.id && this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
          let model = new PrevisaoProducaoEmRedeModel();
          model.id = prod.id;
          model.numeroDeProducaoEstimada = prod.numeroDeProducaoEstimada;
          model.numeroDeReceitaEstimada = prod.numeroDeReceitaEstimada;
          model.percProducaoEstimada = prod.percProducaoEstimada;
          model.percReceitaEstimada = prod.percReceitaEstimada;
          model.unidade = prod.unidade;
          prodEmRed.push(model);
        }
      } else {
        let model = new PrevisaoProducaoEmRedeModel();
        model.id = prod.id;
        model.numeroDeProducaoEstimada = prod.numeroDeProducaoEstimada;
        model.numeroDeReceitaEstimada = prod.numeroDeReceitaEstimada;
        model.percProducaoEstimada = prod.percProducaoEstimada;
        model.percReceitaEstimada = prod.percReceitaEstimada;
        model.unidade = prod.unidade;
        prodEmRed.push(model);
      }
    });
    this.setState({ parceiroList: prodEmRed });
  }

  convertPrevisaoReceita(list) {
    list.forEach((prod) => {
      let model = new PrevisaoReceitaModel();
      model.id = prod.id;
      model.valor = prod.valor || prod.valor >= 0 ? prod.valor : "";
      model.vlrfinanceiro = prod.vlrfinanceiro || prod.vlrfinanceiro >= 0 ? prod.vlrfinanceiro : "";
      model.vlreconomico = prod.vlreconomico || prod.vlreconomico >= 0 ? prod.vlreconomico : "";
      model.receitarepassada = prod.receitarepassada ? prod.receitarepassada : "";
      model.detalhes = prod.detalhes;
      model.atendimento = prod.atendimento;
      model.tipoPrevisaoReceita = prod.tipoPrevisaoReceita;
      model.fontePagadora = prod.fontePagadora;
      model.agenciaBolsa = prod.agenciaBolsa;
      model.ictParceiro = prod.ictParceiro;
      model.agenciaNacional = prod.agenciaNacional;
      model.agenciaFomento = prod.agenciaFomento;
      model.unidade = prod.unidade;
      model.sistemaIndustria = prod.sistemaIndustria;
      model.enableValor = prod.valor >= 0 ? true : false;
      model.enableFinanceiro = prod.vlrfinanceiro >= 0 ? true : false;
      model.enableEconomico = prod.vlreconomico >= 0 ? true : false;

      if (this.isFontePagadora(prod)) {
        let idPanel = this.getIdPanel();
        this.addPrevisaoItemReceita(model, this.refreshContentCollapse(model, idPanel), idPanel);
      } else {
        let prodEmRed = this.state.parceiroList;
        prodEmRed.push(model);
        this.setState({ parceiroList: prodEmRed });
      }
    });
  }

  createRede = async () => {
    let atendimentoStatus = (await API.get(`/rest/gestao/AtendimentoStatus`, { params: { chave: "pronto" } })).data[0];
    let unidade = null;

    //DN e DR não podem criar atendimento parceiro
    if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
      unidade = this.props.usuarioConectado.unidade;
    }

    if (unidade != null) {
      var numeroDeProducaoEstimada = 0;
      var numeroDeReceitaEstimada = 0;
      var vlrfinanceiro = 0;
      var vlreconomico = 0;
      this.state.atendimentoExecutor.previsaoProducaoEmRedeList.forEach((obj) => {
        if (obj.unidade.id === this.props.usuarioConectado.unidade.id) {
          numeroDeProducaoEstimada = obj.numeroDeProducaoEstimada;
          numeroDeReceitaEstimada = obj.numeroDeReceitaEstimada;
        }
      });
      this.state.atendimentoExecutor.previsaoReceitaList.forEach((obj) => {
        if (obj.unidade != null) {
          if (obj.unidade.id === this.props.usuarioConectado.unidade.id) {
            vlrfinanceiro = obj.vlrfinanceiro;
            vlreconomico = obj.vlreconomico;
          }
        }
      });

      let unidadeCliente = (await API.get(`/rest/pessoa/Cliente?isAtivo=true&isUnidadeSenai=true&buscaLivre=` + this.state.atendimentoExecutor.unidade.cnpj)).data[0];

      let atendimento = new AtendimentoModel();
      atendimento.dataEmissao = new Date();
      atendimento.atendimentoStatus = atendimentoStatus;
      atendimento.cliente = unidadeCliente != null ? unidadeCliente : { razaoSocial: "", cpfcnpj: "", enderecoList: [] };
      atendimento.unidade = unidade;
      atendimento.numeroDeProducaoEstimada = numeroDeProducaoEstimada;
      atendimento.numeroDeRelatorioEstimado = 0;
      atendimento.numeroDeReceitaEstimada = numeroDeReceitaEstimada;
      atendimento.vlrFinanceiro = vlrfinanceiro;
      atendimento.vlrEconomico = vlreconomico;
      atendimento.isEmRede = this.state.atendimentoExecutor.isEmRede;
      atendimento.dataInicioPrevista = new Date();
      atendimento.dataConclusaoPrevista = new Date();
      this.setState({ atendimento: atendimento });
      this.addPrevisaoReceitaRede();
      this.updateSetoresPorCnae(this.state.atendimento);
    }
  };

  getStatusList = () => {
    API.get("/rest/gestao/AtendimentoStatus")
      .then((res) => {
        res.data.forEach((data) => {
          if (data.id >= 2 && data.id <= 4) this.statusList.push(data);
        });
      })
      .catch((error) => {
        this.showToast("Erro ao listar os status dos atendimentos. Favor, entrar em contato com o suporte.", "error");
      });
    return this.statusList;
  };

  async componentWillMount() {
    if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
      this.isUO = true;
      this.isDR = false;
    } else if (this.props.usuarioConectado.unidade.tipoUnidade.sigla === "UO") {
      this.isUO = true;
      this.isDR = false;
    } else if (this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DR") {
      this.isUO = false;
      this.isDR = true;
    }
    this.setParametrosAtendimento();
    await this.setTipoProdutoVenda();
    this.configAtendimento();
    this.getAtendimento();
    this.getProdutoRegional();
    this.setState({ tipoPrevisaoReceitaList: (await API.get(`/rest/gestao/TipoPrevisaoReceita`)).data });
    this.getStatusList();
  }

  configAtendimento() {
    if (this.isUO) {
      this.changeInputState(this.props.usuarioConectado.unidade, "unidade");
    }
  }

  setParametrosAtendimento = async () => {
    let parametros = (await API.get(`rest/gestao/ParametroUnidade/produtos`)).data;
    this.setState({ parametros: parametros });
    let chaveSI = (await API.get(`rest/gestao/AtendimentoStatusSI?descricao=%&chave=na`)).data[0];
    this.setState({ chaveSI: chaveSI });
  };

  setTipoProdutoVenda = async () => {
    let tipoVenda = (await API.get(`rest/auxiliar/TipoVenda`)).data;
    let tipoProduto = (await API.get(`rest/auxiliar/TipoProduto`)).data;
    this.setState({ tipoProduto: tipoProduto, tipoVenda: tipoVenda });
  };

  handleAnexarProposta = async (anexo, idProposta) => {
    let file = anexo;
    let formData = new FormData();
    formData.append("name", "file");
    formData.append("file", file);
    formData.append("fileName", file.name);
    if (file.size > 10000000) {
      this.props.closeLoading();
      this.props.showToast("O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.", "error");
      return;
    }

    await API.post("rest/upload/proposta/anexo/" + idProposta, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  anexarAtendimentoPropostaEmNegociacaoOuAceito = async (atendimento) => {
    try {
      const proposta = {
        atendimentoList: [atendimento],
        descricao: this.state.descricaoProposta,
      };

      const response = await API.post("rest/gestao/Proposta", proposta);

      const { id: idProposta } = response.data;

      const responseAtendimento = await API.get(`rest/gestao/Atendimento/${atendimento.id}`);

      const { data } = responseAtendimento;
      if (this.state.atendimentoStatus === 4) {
        const atendimentoStatus = {
          atendimentoList: [atendimento],
          chave: "aceito",
          descricao: "Aceito/Contratado",
          id: 4,
          type: "atendimentoStatus",
        };
        data.atendimentoStatus = atendimentoStatus;
        data.dataAceitacao = this.dataAceitacao + "T00:00:00-03:00";
        await API.post(`rest/gestao/Atendimento/${data.id}`, data);

        if (this.state.uploadAnexoProposta === null) {
          await this.handleAnexarProposta(this.state.uploadAnexoContrato, idProposta);
        } else {
          await this.handleAnexarProposta(this.state.uploadAnexoProposta, idProposta);
          await this.handleAnexarProposta(this.state.uploadAnexoContrato, idProposta);
        }
      }

      /* Status Aceito */
    } catch (error) {
      this.closeLoading();
      this.showToast("Infelizmente houve um erro anexar a proposta. Por favor entre em contato com o suporte.", "error");
    }
  };

  salvarAtendimento = async () => {
    this.showLoading();
    this.configFontesEParceiros();
    await this.configAtendimentoPesquisa();

    if (this.controller) {
      this.controller.abort();
    }

    this.controller = new AbortController();
    const { signal } = this.controller;

    if (this.state.atendimento) this.state.atendimento.cliente.cpfcnpj = Masks.removeMask(this.state.atendimento.cliente.cpfcnpj);
    if (this.props.idAtendimento) {
      if (this.state.isParceiroRede) {
        this.changeInputState(this.state.chaveSI, "atendimentoStatusSI");
        API.post("rest/gestao/Atendimento", this.state.atendimento, { signal })
          .then((res) => {
            var atendimentoRedeAux = this.state.atendimentoRede;
            atendimentoRedeAux.statusNotificacao = this.state.statusNotificacaoAceito;
            atendimentoRedeAux.atendimentoParceiro = res.data;
            const atendimentoIdRedirect = {
              id: this.props.idAtendimento,
            };
            API.post("rest/gestao/AtendimentoRede/" + atendimentoRedeAux.id, atendimentoRedeAux, { signal }).then((res) => {
              this.closeLoading();
              this.showToast("Atendimento parceiro cadastrado com sucesso!", "success", "/cad/atendimento/atendimento", atendimentoIdRedirect);
            });
          })
          .catch((error) => {
            this.closeLoading();
            this.showToast("Infelizmente houve um erro ao cadastrar o atendimento parceiro. Por favor entre em contato com o suporte.", "error");
          });
      } else {
        // Editar Atendimento
        try {
          this.setAtendimentoMetrologia();
          await API.post(`rest/gestao/Atendimento/${this.props.idAtendimento}`, this.state.atendimento, { signal });
          const atendimentoIdRedirect = {
            id: this.props.idAtendimento,
          };
          this.closeLoading();
          this.showToast("Atendimento atualizado com sucesso!", "success", "/cad/atendimento/atendimento", atendimentoIdRedirect);
        } catch (error) {
          console.log(error);
          if (error.name === "AbortError") {
            console.log("A requisição foi cancelada.");
            this.showToast("Infelizmente houve um erro ao atualizar o atendimento. Por favor entre em contato com o suporte.", "error");
          }
          this.closeLoading();
          this.showToast("Infelizmente houve um erro ao atualizar o atendimento. Por favor entre em contato com o suporte.", "error");
        }
      }
    } else {
      // Criar Atendimento
      try {
        this.changeInputState({ id: 2 }, "atendimentoStatus");
        this.changeInputState(this.state.chaveSI, "atendimentoStatusSI");

        if (this.state.atendimento.previsaoProducaoEmRedeList.length > 1) {
          this.changeInputState(true, "isEmRede");
        }

        const response = await API.post("rest/gestao/Atendimento", this.state.atendimento, { signal });
        const { data: atendimento } = response;
        if (this.state.isAtendimentoBMP) {
          let porteParaEnvio = this.state.idPorteClienteReceita;
          if (!porteParaEnvio) {
            porteParaEnvio = this.state.atendimento.cliente?.porteCliente?.id;
          }
          await newBackend.post("/atendimento-porte-contratacao", { id_porte_cliente: porteParaEnvio, id_atendimento: atendimento.id }, { signal });
        }
        if ([3, 4].includes(this.state.atendimentoStatus)) {
          await this.anexarAtendimentoPropostaEmNegociacaoOuAceito(atendimento);
        }
        this.closeLoading();
        this.setState({ openModalConfirmaStatusInicial: false });
        this.showToast("Atendimento cadastrado com sucesso!", "success", "/cad/atendimento/atendimento", atendimento);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("A requisição foi cancelada.");
        }
        this.closeLoading();
        this.showToast("Infelizmente houve um erro ao cadastrar o atendimento. Por favor entre em contato com o suporte.", "error");
      }
    }
  };

  setAtendimentoMetrologia() {
    if (this.isMetrologia) {
      let atendimento = this.state.atendimento;
      atendimento.areaPesquisaList = [];
      this.setState({ atendimento: atendimento });
    }
  }

  async configFontesEParceiros() {
    let listProducaoRede = [];
    let listPrevisaoReceita = this.state.fontesRecursos;
    this.state.parceiroList.forEach((parc) => {
      if (parc.tipoPrevisaoReceita) {
        listPrevisaoReceita.push(parc);
      } else {
        listProducaoRede.push(parc);
      }
    });
    let atendimento = this.state.atendimento;
    atendimento.previsaoProducaoEmRedeList = listProducaoRede;
    atendimento.previsaoReceitaList = listPrevisaoReceita;
    await this.setState({ atendimento: atendimento });
  }

  configAtendimentoPesquisa() {
    if (this.state.atendimento.atendimentoPesquisa) {
      if (
        (!this.state.atendimento.atendimentoPesquisa.tipoVenda || (this.state.atendimento.atendimentoPesquisa.tipoVenda && !this.state.atendimento.atendimentoPesquisa.tipoVenda.id)) &&
        (!this.state.atendimento.atendimentoPesquisa.tipoProduto || (this.state.atendimento.atendimentoPesquisa.tipoProduto && !this.state.atendimento.atendimentoPesquisa.tipoProduto.id))
      ) {
        let atend = this.state.atendimento;
        atend.atendimentoPesquisa = null;
        this.setState({ atendimento: atend });
      } else if (!this.state.atendimento.atendimentoPesquisa.tipoVenda || !this.state.atendimento.atendimentoPesquisa.tipoVenda.id || this.state.atendimento.atendimentoPesquisa.tipoVenda.id === 0) {
        let atend = this.state.atendimento;
        atend.atendimentoPesquisa.tipoVenda = null;
        this.setState({ atendimento: atend });
      } else if (
        !this.state.atendimento.atendimentoPesquisa.tipoProduto ||
        !this.state.atendimento.atendimentoPesquisa.tipoProduto.id ||
        this.state.atendimento.atendimentoPesquisa.tipoProduto.id === 0
      ) {
        let atend = this.state.atendimento;
        atend.atendimentoPesquisa.tipoProduto = null;
        this.setState({ atendimento: atend });
      }
    }
  }

  checkStatusAlterarStep1() {
    if (this.state.atendimento.atendimentoStatus.chave === undefined) {
      return false;
    }
    return (
      this.state.atendimento.atendimentoStatus.chave !== "pronto" &&
      this.state.atendimento.atendimentoStatus.chave !== "negociacao" &&
      this.state.atendimento.atendimentoStatus.chave !== "aceito" &&
      this.state.atendimento.atendimentoStatus.chave !== "emexecucao"
    );
  }

  checkStatusAlterarStep2() {
    if (this.state.atendimento.atendimentoStatus.chave === undefined) {
      return false;
    }
    return this.state.atendimento.atendimentoStatus.chave !== "pronto" && this.state.atendimento.atendimentoStatus.chave !== "negociacao";
  }

  checkUnidadeAlterar = () => {
    var idUnidade = 0;
    if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
      idUnidade = this.props.usuarioConectado.unidade.id;
    } else {
      idUnidade = this.props.usuarioConectado.unidade.id;
    }
    return this.isDR || idUnidade === this.state.atendimento.unidade.id || idUnidade === this.state.atendimento.unidade.unidadePai.id;
  };

  updateSetoresPorCnae = async () => {
    let atendimento = this.state.atendimento;
    // if (atendimento.setor === '' || atendimento.setor.id === 0 || this.state.setoresPorCnaeObjects.length === 0) {
    let cliente = atendimento.cliente;
    let cnae = cliente.cnae ? `?divisao=${cliente.cnae.substr(0, 2)}` : "";
    let cnaesSecundarios = cliente.cnaeSecundario
      ? "&divisao=" +
        cliente.cnaeSecundario
          .split(";")
          .map((each) => {
            return each.substr(0, 2);
          })
          .join("&divisao=")
      : "";
    let setores = cnae !== "" || cnaesSecundarios !== "" ? await API.get(`/rest/gestao/Setor${cnae + cnaesSecundarios}`) : [];

    setores = cliente.tipoPessoa === 0 ? await API.get(`/rest/gestao/Setor`) : setores;

    this.setState({ setoresPorCnaeObjects: setores.data });
    // }
  };

  changeInputState = async (e, nomeProp) => {
    let atend = this.state.atendimento;
    let errors = this.state.errors;
    if (e !== null && e.target != null) {
      if (nomeProp === "dataInicioPrevista" || nomeProp === "dataConclusaoPrevista") {
        if (nomeProp === "dataInicioPrevista") {
          atend[`${nomeProp}`] = e.target.value + "T00:00:00-03:00";
        } else if (nomeProp === "dataConclusaoPrevista") {
          atend[`${nomeProp}`] = e.target.value + "T23:59:59-03:00";
        }
      } else {
        atend[`${nomeProp}`] = e.target.value;
      }

      if (nomeProp === "numeroProjetoSGF") {
        atend[`${nomeProp}`] = e.target.value;
      }

      if (nomeProp === "titulo") {
        atend[`${nomeProp}`] = atend[`${nomeProp}`].toUpperCase();
      }
      if (e.target.required && (e.target.value === null || e.target.value === "")) {
        errors[`${nomeProp}`].error = "Campo obrigatório";
        this.setState({ errors: errors });
      } else if ((nomeProp === "dataInicioPrevista" || nomeProp === "dataConclusaoPrevista") && this.formatData(atend.dataInicioPrevista) > this.formatData(atend.dataConclusaoPrevista)) {
        errors[nomeProp].error = "Data de início deve ser menor ou igual a data de conclusão";
        this.setState({ errors: errors });
      } else if (this.state.errors[`${nomeProp}`] != null) {
        errors[`${nomeProp}`].error = "";
        this.setState({ errors: errors });
      }
      this.setState({ atendimento: atend });
    } else if (e !== null && e.type !== null) {
      if (nomeProp === "produtoRegional" && this.state.atendimento.produtoRegional.id !== e.id) {
        atend[`${nomeProp}`] = e;
        this.checkAtendimentoBMP();
        this.checkConstaEmOutroAtendimentoBMP();
        this.setState({ atendimento: atend }, this.changeProdutoRegional());
      } else if (nomeProp === "cliente") {
        if (atend.cliente.id !== e.id) {
          atend[`${nomeProp}`] = e;
          this.updateSetoresPorCnae();
          atend.setor = "";
          await this.getPorteClienteReceita();
          this.checkPortesClientesBMP(atend[`${nomeProp}`]);
          this.checkConstaEmOutroAtendimentoBMP();
        }
        this.setState({ atendimento: atend }, this.changeCliente());
      } else if (nomeProp === "setor") {
        if (e && atend.setor.id !== e.id) {
          atend[`${nomeProp}`] = e;
          this.setState({ atendimento: atend });
        }
      } else if (nomeProp === "recursoRegulado") {
        this.setState({ recursoRegulado: e });
      } else if (nomeProp === "fontePagadora") {
        this.setState({ fontePagadora: e });
      } else if (nomeProp === "producaoTotalPrevista") {
        if (this.state.isAtendimentoBMP) {
          this.checkProducaoPorPorte(e);
        }
      } else {
        atend[`${nomeProp}`] = e;
        this.setState({ atendimento: atend });
      }
    }
  };

  changeInputEscopoIndefinido = () => {
    let atend = this.state.atendimento;
    atend[`isEscopoIndefinido`] = !this.state.atendimento.isEscopoIndefinido;
    this.setState({ atendimento: atend });
  };

  changeProdutoRegional = () => {
    let atendimento = this.state.atendimento;
    atendimento.complexidade = "";
    atendimento.grauInovacao = "";
    atendimento.abrangenciaImpacto = "";
    atendimento.isValorHora = false;
    atendimento.isEscopoIndefinido = false;
    atendimento.isEmRede = false;
    atendimento.setor = "";
    atendimento.apl = "";
    this.isAtendimentoBP = false;
    this.isAtendimentoIA = false;
    this.isAtendimentoIPEA = false;
    this.isAtendimentoIE = false;
    this.isAtendimentoLEAN28 = false;
    this.isAtendimentoLEAN56 = false;
    this.isAtendimentoMENTORIA = false;
    this.isAtendimentoIA180 = false;
    this.isAtendimentoDIGITAL = false;
    this.isAtendimentoBrasilMaisLEAN = false;
    if (atendimento.produtoRegional.id != null && atendimento.produtoRegional.id > 0 && atendimento.produtoRegional.produtoNacional != null) {
      atendimento.produtoLinha = this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.produtoLinha;
      this.updateProdutoLinha(this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.produtoLinha);
      this.showLoading();
      if (!this.state.isParceiroRede) this.setState({ previsaoReceitaList: [], content: [] });
      if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO)) {
        this.isAtendimentoBP = true;
        atendimento.previsaoProducaoEmRedeList = [];
        atendimento.numeroDeReceitaEstimada = 3000;
        atendimento.numeroDeProducaoEstimada = 120;
        this.addPrevisaoReceitaBP();
      } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_INDUSTRIA_MAIS_AVANCADA)) {
        this.isAtendimentoIA = true;
        atendimento.previsaoProducaoEmRedeList = [];
        atendimento.numeroDeProducaoEstimada = 160;
        this.addPrevisaoReceitaIA();
      } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_IPEA)) {
        this.isAtendimentoIPEA = true;
        this.closeLoading();
      } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_EFICIENCIA_ENERGETICA)) {
        this.isAtendimentoIE = true;
        atendimento.previsaoProducaoEmRedeList = [];
        atendimento.numeroDeProducaoEstimada = 140;
        this.addPrevisaoReceitaIE();
      } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_LEAN_28)) {
        this.isAtendimentoLEAN28 = true;
        atendimento.previsaoProducaoEmRedeList = [];
        atendimento.numeroDeProducaoEstimada = 28;
        this.addPrevisaoReceitaLEAN("B+P EAD Lean 28");
      } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_LEAN_56)) {
        this.isAtendimentoLEAN56 = true;
        atendimento.previsaoProducaoEmRedeList = [];
        atendimento.numeroDeProducaoEstimada = 56;
        this.addPrevisaoReceitaLEAN("B+P EAD Lean 56");
      } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_MENTORIA)) {
        this.isAtendimentoMENTORIA = true;
        atendimento.previsaoProducaoEmRedeList = [];
        atendimento.numeroDeProducaoEstimada = 56;
        this.addPrevisaoReceitaLEAN("Mentoria Lean");
      } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_CONSULTORIA_MANUFATURA_180)) {
        this.isAtendimentoIA180 = true;
        atendimento.previsaoProducaoEmRedeList = [];
        atendimento.numeroDeProducaoEstimada = 180;
        this.addPrevisaoReceitaIA180();
      } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_MENTORIA_DIGITAL)) {
        this.isAtendimentoDIGITAL = true;
        atendimento.previsaoProducaoEmRedeList = [];
        atendimento.numeroDeProducaoEstimada = 80;
        this.addPrevisaoReceitaDIGITAL();
      } else if (atendimento.produtoRegional.produtoNacional.id === Number(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_MENTORIA_LEAN)) {
        this.isAtendimentoBrasilMaisLEAN = true;
        atendimento.previsaoProducaoEmRedeList = [];
        atendimento.numeroDeProducaoEstimada = 16;
        this.addPrevisaoReceitaBrasilMaisLEAN();
      } else {
        this.closeLoading();
      }
      this.setState({ atendimento: atendimento });
    }
  };

  changeCliente = () => {
    let previsaoList = this.state.previsaoReceitaList;
    for (var i = 0; i < previsaoList.length; i++) {
      if (previsaoList[i].tipoPrevisaoReceita.id === 1) this.changeValorFonteRecurso(this.state.atendimento.cliente, "fontePagadora", previsaoList[i].idPanel);
    }
  };

  setError() {
    let errors = { titulo: { error: "" }, dataInicioPrevista: { error: "" }, dataConclusaoPrevista: { error: "" } };
    return errors;
  }

  changeAtendimentoPesquisa = (e, prop) => {
    let atendimentoPesquisa = this.state.atendimento.atendimentoPesquisa ? this.state.atendimento.atendimentoPesquisa : new AtendimentoPesquisaModel();
    atendimentoPesquisa[`${prop}`] = e;
    this.changeInputState(atendimentoPesquisa, "atendimentoPesquisa");
  };

  getDescricaoFonte(font) {
    switch (font.tipoPrevisaoReceita.id) {
      case 1:
        return font.tipoPrevisaoReceita.descricao || "FONTE PAGADORA";
      case 5:
      case 7:
      case 8:
      case 9:
        return "FOMENTO EXTERNO";
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
        return "SISTEMA INDÚSTRIA";
      default:
        return font.tipoPrevisaoReceita.descricao || "FONTE PAGADORA";
    }
  }

  getIdFonte(font) {
    switch (font.tipoPrevisaoReceita.id) {
      case 1:
        return font.fontePagadora.id;
      case 5:
        return font.ictParceiro.id;
      case 7:
        return font.agenciaNacional.id;
      case 8:
        return font.agenciaFomento.id;
      case 10:
        return font.unidade.id;
      case 11:
        return font.unidade.id;
      case 12:
      case 14:
        return font.unidade.id;
      case 13:
        return font.sistemaIndustria ? font.sistemaIndustria.id : font.fontePagadora.id;
      default:
    }
  }

  getIdPanel() {
    let count = this.state.panelCount + 1;
    this.setState({ panelCount: count });
    return count;
  }

  getSumFontePagadora(fonte) {
    let total = 0;
    if (fonte.valor && fonte.valor !== "") {
      total = total + fonte.valor;
    }
    if (fonte.vlreconomico && fonte.vlreconomico !== "") {
      total = total + fonte.vlreconomico;
    }
    if (fonte.vlrfinanceiro && fonte.vlrfinanceiro !== "") {
      total = total + fonte.vlrfinanceiro;
    }
    return total;
  }

  verifyLEAN = async (tipoFonte) => {
    let receita = this.getReceita(tipoFonte);
    let previsaoReceitaList = this.state.previsaoReceitaList;
    if (this.isAtendimentoLEAN28) {
      previsaoReceitaList[0].valor = 4420;
      await this.setState({ previsaoReceitaList: previsaoReceitaList });
      if (this.state.previsaoReceitaList.length > 1) await this.deletePanelItem(this.state.previsaoReceitaList[1].idPanel);
      if (tipoFonte === 1) receita.valor = 780; //mercado
      else if (tipoFonte === 11) receita.vlreconomico = 780;
    } else if (this.isAtendimentoLEAN56) {
      previsaoReceitaList[0].valor = 7990;
      await this.setState({ previsaoReceitaList: previsaoReceitaList });
      if (this.state.previsaoReceitaList.length > 1) await this.deletePanelItem(this.state.previsaoReceitaList[1].idPanel);
      if (tipoFonte === 1) receita.valor = 1410; //mercado
      else if (tipoFonte === 11) receita.vlreconomico = 1410; //dr
    } else if (this.isAtendimentoMENTORIA) {
      previsaoReceitaList[0].valor = 6800;
      await this.setState({ previsaoReceitaList: previsaoReceitaList });
      if (this.state.previsaoReceitaList.length > 1) await this.deletePanelItem(this.state.previsaoReceitaList[1].idPanel);
      if (tipoFonte === 1) receita.valor = 1200; //mercado
      else if (tipoFonte === 11) receita.vlreconomico = 1200; //dr
    }
    let cont = 0;
    if (tipoFonte === 1) cont = this.addFonteMercado(receita, this.getIdPanel());
    else if (tipoFonte === 11) cont = this.addDRAtendimento(receita, this.getIdPanel());
    this.addPrevisaoItemReceita(receita, cont, cont.id);
  };

  addFonteMercado(recMercado, idPanel) {
    let fontMercado = {
      id: idPanel,
      title: (
        <Grid container>
          <Typography style={{ flexBasis: "100%" }} noWrap>
            Fonte Pagadora | MERCADO
          </Typography>
        </Grid>
      ),
      body: (
        <Grid container>
          <Grid item xs={12}>
            <AutoComplete
              placeholder={"Digite o nome da fonte pagadora"}
              itemKey={"id"}
              key="autoCompleteFonteMercado"
              path={"/rest/pessoa/Cliente/0/30?isAtivo=true&isUnidadeSenai=false&buscaLivre="}
              itemlabel={"nome"}
              initialSelectedItem={recMercado.fontePagadora}
              itemTemplate={this.createTemplateCliente}
              getInput={(e) => this.changeValorFonteRecurso(e, "fontePagadora", idPanel)}
              madatory={true}
            />
          </Grid>
          {this.getResourcesFields(recMercado, idPanel, false, true, true)}
        </Grid>
      ),
      trashButton: (
        <Grid container>
          <GenericButton
            color={"transparent"}
            click={(e) => {
              this.deletePanelItem(idPanel);
            }}
            subClass={"icon-and-button"}
            icon={"trash.png"}
            disabled={
              this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL
            }
          />
        </Grid>
      ),
    };
    return fontMercado;
  }

  addFonteICT(recICT, idPanel) {
    let fontICT = {
      id: idPanel,
      title: (
        <Grid container>
          <Typography style={{ flexBasis: "100%" }} noWrap>
            Fonte Pagadora | ICT
          </Typography>
        </Grid>
      ),
      body: (
        <Grid container>
          <Grid item xs={6} className={"paddingForm"}>
            <AutoComplete
              placeholder={"Digite o ICT parceiro"}
              itemKey={"id"}
              key="autoCompleteFonteICT"
              path={"/rest/gestao/IctParceiro?descricao=%"}
              posPath={"%"}
              itemlabel={"descricao"}
              initialSelectedItem={recICT.ictParceiro}
              getInput={(e) => this.changeValorFonteRecurso(e, "ictParceiro", idPanel)}
              madatory={true}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 10 }}>
            <FormField
              type={"text"}
              changeValue={(e) => this.changeValorFonteRecurso(e.target.value, "detalhes", idPanel)}
              inputValue={recICT.detalhes}
              disabled={this.state.atendimento.isValorHora}
            />
          </Grid>
          {this.getResourcesFields(recICT, idPanel, false, true, true)}
        </Grid>
      ),
      trashButton: (
        <Grid container>
          <GenericButton
            color={"transparent"}
            click={(e) => {
              this.deletePanelItem(idPanel);
            }}
            subClass={"icon-and-button"}
            icon={"trash.png"}
            disabled={
              this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL
            }
          />
        </Grid>
      ),
    };
    return fontICT;
  }

  addFonteAgenciaNacional(recAgenciaNacional, idPanel) {
    let fontAgenciaNacional = {
      id: idPanel,
      title: (
        <Grid container>
          <Typography style={{ flexBasis: "100%" }} noWrap>
            Fonte Pagadora | AGÊNCIA NACIONAL
          </Typography>
        </Grid>
      ),
      body: (
        <Grid container>
          <Grid item xs={12}>
            <AutoComplete
              placeholder={"Digite a agência nacional"}
              itemKey={"id"}
              path={"/rest/gestao/AgenciaNacional?descricao=%"}
              posPath={"%"}
              itemlabel={"descricao"}
              key="autoCompleteAgenciaNacional"
              madatory={true}
              initialSelectedItem={recAgenciaNacional.agenciaNacional}
              getInput={(e) => this.changeValorFonteRecurso(e, "agenciaNacional", idPanel)}
            />
          </Grid>
          {this.getResourcesFields(recAgenciaNacional, idPanel, false, true, true)}
        </Grid>
      ),
      trashButton: (
        <Grid container>
          <GenericButton
            color={"transparent"}
            click={(e) => {
              this.deletePanelItem(idPanel);
            }}
            subClass={"icon-and-button"}
            icon={"trash.png"}
            disabled={
              this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL
            }
          />
        </Grid>
      ),
    };
    return fontAgenciaNacional;
  }

  addFonteAgenciaFomento(recAgenciaFomento, idPanel) {
    let fontAgenciaFomento = {
      id: idPanel,
      title: (
        <Grid container>
          <Typography style={{ flexBasis: "100%" }} noWrap>
            Fonte Pagadora | AGÊNCIA FOMENTO
          </Typography>
        </Grid>
      ),
      body: (
        <Grid container>
          <Grid item xs={12}>
            <AutoComplete
              placeholder={"Digite a agência de fomento"}
              itemKey={"id"}
              path={"/rest/gestao/AgenciaFomento?descricao=%"}
              posPath={"%"}
              itemlabel={"descricao"}
              key="autoCompleteAgenciaFomento"
              initialSelectedItem={recAgenciaFomento.agenciaFomento}
              getInput={(e) => this.changeValorFonteRecurso(e, "agenciaFomento", idPanel)}
              madatory={true}
              toUpperCase={true}
              disabled={
                this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL
              }
              itemTemplate={this.createTemplateAgenciaFomento}
            />
          </Grid>
          {this.getResourcesFields(recAgenciaFomento, idPanel, false, true, true)}
        </Grid>
      ),
      trashButton: (
        <Grid container>
          <GenericButton
            color={"transparent"}
            click={(e) => {
              this.deletePanelItem(idPanel);
            }}
            subClass={"icon-and-button"}
            icon={"trash.png"}
            disabled={
              this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL
            }
          />
        </Grid>
      ),
    };
    return fontAgenciaFomento;
  }

  addUOAtendimento(recUOAtendimento, idPanel) {
    recUOAtendimento.unidade = this.state.atendimento.unidade;
    let fontUOAtendimento = {
      id: idPanel,
      title: (
        <Grid container>
          <Typography style={{ flexBasis: "100%" }} noWrap>
            Fonte Pagadora | UO DO ATENDIMENTO
          </Typography>
        </Grid>
      ),
      body: (
        <Grid container>
          <Grid item xs={12}>
            <FormField type={"text"} changeValue={(e) => {}} inputValue={recUOAtendimento.unidade.abreviacao} disabled={true} />
          </Grid>
          {this.getResourcesFields(recUOAtendimento, idPanel, false, false, false)}
        </Grid>
      ),
      trashButton: (
        <Grid container>
          <GenericButton
            color={"transparent"}
            click={(e) => {
              this.deletePanelItem(idPanel);
            }}
            subClass={"icon-and-button"}
            icon={"trash.png"}
            disabled={
              this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL
            }
          />
        </Grid>
      ),
    };
    return fontUOAtendimento;
  }

  addSesiSenaiDN(redSesiSenaiDN, idPanel) {
    redSesiSenaiDN.unidade = this.state.atendimento.unidade;
    let fontSesiSenaiDN = {
      id: idPanel,
      title: (
        <Grid container>
          <Typography style={{ flexBasis: "100%" }} noWrap>
            Fonte Pagadora | SESI/SENAI DN
          </Typography>
        </Grid>
      ),
      body: (
        <Grid container>
          <Grid item xs={12}>
            <FormField type={"text"} changeValue={(e) => {}} inputValue={redSesiSenaiDN.unidade.abreviacao} disabled={true} />
          </Grid>
          {this.getResourcesFields(redSesiSenaiDN, idPanel, false, false, false)}
        </Grid>
      ),
      trashButton: (
        <Grid container>
          <GenericButton
            color={"transparent"}
            click={(e) => {
              this.deletePanelItem(idPanel);
            }}
            subClass={"icon-and-button"}
            icon={"trash.png"}
            disabled={
              this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL
            }
          />
        </Grid>
      ),
    };
    return fontSesiSenaiDN;
  }

  addDRAtendimento(recDRAtendimento, idPanel) {
    let fontDRAtendimento = {
      id: idPanel,
      title: (
        <Grid container>
          <Typography style={{ flexBasis: "100%" }} noWrap>
            Fonte Pagadora | DR DO ATENDIMENTO
          </Typography>
        </Grid>
      ),
      body: (
        <Grid container>
          <Grid item xs={12}>
            <FormField type={"text"} inputValue={recDRAtendimento.unidade.abreviacao} changeValue={(e) => {}} disabled={true} />
          </Grid>
          {this.getResourcesFields(recDRAtendimento, idPanel, true, false, false)}
        </Grid>
      ),
      trashButton: (
        <Grid container>
          <GenericButton
            color={"transparent"}
            click={(e) => {
              this.deletePanelItem(idPanel);
            }}
            subClass={"icon-and-button"}
            icon={"trash.png"}
            disabled={
              this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL
            }
          />
        </Grid>
      ),
    };
    return fontDRAtendimento;
  }

  addSenaiParceiro(recSenaiParceiro, idPanel) {
    let fontSenaiParceiro = {
      id: idPanel,
      title: (
        <Grid container>
          <Typography style={{ flexBasis: "100%" }} noWrap>
            Fonte Pagadora | SENAI PARCEIRO
          </Typography>
        </Grid>
      ),
      body: (
        <Grid container>
          <Grid item xs={12}>
            <AutoComplete
              placeholder={"Digite a fonte"}
              itemKey={"id"}
              itemlabel={"abreviacao"}
              key="autoCompleteSenaiParceiro"
              path={"/rest/gestao/Unidade/0/30?buscaLivre="}
              initialSelectedItem={recSenaiParceiro.unidade}
              madatory={true}
              itemTemplate={this.createTemplateUnidade}
              getInput={(e) => this.changeValorFonteRecurso(e, "unidade", idPanel)}
            />
          </Grid>
          {this.getResourcesFields(recSenaiParceiro, idPanel, false, false, false)}
        </Grid>
      ),
      trashButton: (
        <Grid container>
          <GenericButton
            color={"transparent"}
            click={(e) => {
              this.deletePanelItem(idPanel);
            }}
            subClass={"icon-and-button"}
            icon={"trash.png"}
            disabled={
              this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL
            }
          />
        </Grid>
      ),
    };
    return fontSenaiParceiro;
  }

  addSistemaIndustria(recSistemaIndustria, idPanel) {
    let fontSistemaIndustria = {
      id: idPanel,
      title: (
        <Grid container>
          <Typography style={{ flexBasis: "100%" }} noWrap>
            Fonte Pagadora | SISTEMA INDÚSTRIA
          </Typography>
        </Grid>
      ),
      body: (
        <Grid container>
          <Grid item xs={12}>
            <AutoComplete
              placeholder={"Digite a fonte"}
              itemKey={"id"}
              key="autoCompleteSistemaIndustria"
              madatory={true}
              itemlabel={"razaoSocial"}
              itemTemplate={this.createTemplateCliente}
              path={"/rest/pessoa/Cliente/0/3?isAtivo=true&isUnidadeSenai=true&buscaLivre="}
              getInput={(e) => this.changeValorFonteRecurso(e, "sistemaIndustria", idPanel)}
              initialSelectedItem={recSistemaIndustria.sistemaIndustria}
              disabled={
                this.isAtendimentoBP ||
                this.isAtendimentoBrasilMaisLEAN ||
                this.isAtendimentoIPEA ||
                this.isAtendimentoIE ||
                this.isAccepted ||
                this.isAtendimentoIA180 ||
                this.isAtendimentoDIGITAL ||
                this.isAtendimentoLEAN()
              }
            />
            {this.state.isParceiroRede && <label>A fonte pagadora deve ser a unidade executora.</label>}
          </Grid>
          {this.getResourcesFields(recSistemaIndustria, idPanel, false, false, false)}
        </Grid>
      ),
      trashButton: (
        <Grid container>
          <GenericButton
            color={"transparent"}
            click={(e) => {
              this.deletePanelItem(idPanel);
            }}
            subClass={"icon-and-button"}
            icon={"trash.png"}
            disabled={
              this.isAtendimentoBP ||
              this.isAtendimentoBrasilMaisLEAN ||
              this.isAtendimentoIPEA ||
              this.isAtendimentoIE ||
              this.isAccepted ||
              this.isAtendimentoIA180 ||
              this.isAtendimentoDIGITAL ||
              this.state.isParceiroRede ||
              this.isAtendimentoLEAN()
            }
          />
        </Grid>
      ),
    };
    return fontSistemaIndustria;
  }

  addPrevisaoReceitaRede = () => {
    let previsaoReceita = new PrevisaoReceitaModel();
    this.state.tipoPrevisaoReceitaList.forEach((eachTipoPrevisaoReceita) => {
      if (eachTipoPrevisaoReceita.descricao === "SISTEMA INDÚSTRIA") previsaoReceita.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
    });
    previsaoReceita.isFonteReceita = true;
    previsaoReceita.valor = this.state.atendimento.numeroDeReceitaEstimada;
    previsaoReceita.sistemaIndustria = this.state.atendimento.cliente;

    let cont = this.addSistemaIndustria(previsaoReceita, this.getIdPanel());
    this.addPrevisaoItemReceita(previsaoReceita, cont, cont.id);

    if (this.state.atendimento.vlrFinanceiro > 0 || this.state.atendimento.vlrEconomico > 0) {
      previsaoReceita = new PrevisaoReceitaModel();
      this.state.tipoPrevisaoReceitaList.forEach((eachTipoPrevisaoReceita) => {
        if (eachTipoPrevisaoReceita.descricao === "UO DO ATENDIMENTO") previsaoReceita.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
      });
      previsaoReceita.isFonteReceita = true;
      previsaoReceita.unidade = this.state.atendimento.unidade;
      previsaoReceita.vlrfinanceiro = this.state.atendimento.vlrFinanceiro;
      previsaoReceita.vlreconomico = this.state.atendimento.vlrEconomico;
      let cont = this.addUOAtendimento(previsaoReceita, this.getIdPanel());
      this.addPrevisaoItemReceita(previsaoReceita, cont, cont.id);
    }
  };

  addPrevisaoReceitaBP = () => {
    let previsaoReceita = new PrevisaoReceitaModel();
    this.state.tipoPrevisaoReceitaList.forEach((eachTipoPrevisaoReceita) => {
      if (eachTipoPrevisaoReceita.descricao === "MERCADO") previsaoReceita.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
    });
    previsaoReceita.isFonteReceita = true;
    previsaoReceita.valor = 3000;

    let cont = this.addFonteMercado(previsaoReceita, this.getIdPanel());
    this.addPrevisaoItemReceita(previsaoReceita, cont, cont.id);
    this.closeLoading();
  };

  addPrevisaoReceitaIA = () => {
    let previsaoReceita = new PrevisaoReceitaModel();
    this.state.tipoPrevisaoReceitaList.forEach((eachTipoPrevisaoReceita) => {
      if (eachTipoPrevisaoReceita.descricao === "MERCADO") previsaoReceita.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
    });
    previsaoReceita.isFonteReceita = true;
    let cont = this.addFonteMercado(previsaoReceita, this.getIdPanel());
    this.addPrevisaoItemReceita(previsaoReceita, cont, cont.id);
    this.closeLoading();
  };

  addPrevisaoReceitaIPEA = async () => {
    await API.get("rest/gestao/AgenciaFomento?descricao=ABDI").then((result) => {
      let previsaoReceita = new PrevisaoReceitaModel();
      this.state.tipoPrevisaoReceitaList.forEach((eachTipoPrevisaoReceita) => {
        if (eachTipoPrevisaoReceita.descricao === "AGÊNCIA DE FOMENTO") previsaoReceita.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
      });
      previsaoReceita.isFonteReceita = true;
      previsaoReceita.agenciaFomento = result.data[0];
      if (this.state.atendimento.tipoBPIpea === "CONSULTORIA") {
        previsaoReceita.valor = 15000;
      } else if (this.state.atendimento.tipoBPIpea === "DIAGNOSTICO") {
        previsaoReceita.valor = 1800;
      }
      let cont = this.addFonteAgenciaFomento(previsaoReceita, this.getIdPanel());
      this.addPrevisaoItemReceita(previsaoReceita, cont, cont.id);
      if (this.state.atendimento.tipoBPIpea === "CONSULTORIA") {
        previsaoReceita = new PrevisaoReceitaModel();
        this.state.tipoPrevisaoReceitaList.forEach((eachTipoPrevisaoReceita) => {
          if (eachTipoPrevisaoReceita.descricao === "MERCADO") previsaoReceita.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
        });
        previsaoReceita.isFonteReceita = true;
        previsaoReceita.valor = 3000;
        cont = this.addFonteMercado(previsaoReceita, this.getIdPanel());
        this.addPrevisaoItemReceita(previsaoReceita, cont, cont.id);
      }
      this.closeLoading();
    });
  };

  addPrevisaoReceitaIE = async () => {
    await API.get("rest/gestao/AgenciaFomento?descricao=Eletrobrás").then((result) => {
      let previsaoReceita1 = new PrevisaoReceitaModel();
      let previsaoReceita2 = new PrevisaoReceitaModel();
      this.state.tipoPrevisaoReceitaList.forEach((eachTipoPrevisaoReceita) => {
        if (eachTipoPrevisaoReceita.descricao === "MERCADO") previsaoReceita1.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
        if (eachTipoPrevisaoReceita.descricao === "AGÊNCIA DE FOMENTO") previsaoReceita2.tipoPrevisaoReceita = eachTipoPrevisaoReceita; //agencia de fomento
      });
      previsaoReceita1.isFonteReceita = true;
      previsaoReceita1.valor = 4820;
      previsaoReceita2.isFonteReceita = true;
      previsaoReceita2.agenciaFomento = result.data[0];
      previsaoReceita2.valor = 16180;
      let cont = this.addFonteMercado(previsaoReceita1, this.getIdPanel());
      this.addPrevisaoItemReceita(previsaoReceita1, cont, cont.id);
      cont = this.addFonteAgenciaFomento(previsaoReceita2, this.getIdPanel());
      this.addPrevisaoItemReceita(previsaoReceita2, cont, cont.id);
      this.closeLoading();
    });
  };

  addPrevisaoReceitaLEAN = async (tipo) => {
    await API.get("rest/pessoa/Cliente?id=29587&isUnidadeSenai=true&isAtivo=true").then((result) => {
      //SENAI DN
      let previsaoReceita1 = new PrevisaoReceitaModel();
      this.state.tipoPrevisaoReceitaList.forEach((eachTipoPrevisaoReceita) => {
        if (eachTipoPrevisaoReceita.descricao === "SISTEMA INDÚSTRIA") previsaoReceita1.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
        //if (eachTipoPrevisaoReceita.descricao === 'SISTEMA INDÚSTRIA') previsaoReceita1.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
      });
      previsaoReceita1.isFonteReceita = true;
      previsaoReceita1.sistemaIndustria = result.data[0];
      if (tipo === "B+P EAD Lean 28") {
        previsaoReceita1.valor = 4420;
      } else if (tipo === "B+P EAD Lean 56") {
        previsaoReceita1.valor = 7990;
      } else if (tipo === "Mentoria Lean") {
        previsaoReceita1.valor = 6800;
      }
      let cont = this.addSistemaIndustria(previsaoReceita1, this.getIdPanel());
      this.addPrevisaoItemReceita(previsaoReceita1, cont, cont.id);
      this.closeLoading();
    });
  };

  addPrevisaoReceitaIA180 = async () => {
    await API.get("rest/pessoa/Cliente?id=29587&isUnidadeSenai=true&isAtivo=true").then((result) => {
      //SENAI DN
      let previsaoReceita1 = new PrevisaoReceitaModel();
      let previsaoReceita2 = new PrevisaoReceitaModel();
      this.state.tipoPrevisaoReceitaList.forEach((eachTipoPrevisaoReceita) => {
        if (eachTipoPrevisaoReceita.descricao === "SISTEMA INDÚSTRIA") previsaoReceita1.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
        if (eachTipoPrevisaoReceita.descricao === "MERCADO") previsaoReceita2.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
      });
      previsaoReceita1.isFonteReceita = true;
      previsaoReceita1.sistemaIndustria = result.data[0];
      previsaoReceita1.valor = 27000;
      previsaoReceita2.isFonteReceita = true;
      previsaoReceita2.valor = 5000;
      previsaoReceita2.vlrfinanceiro = 3000;
      let cont = this.addSistemaIndustria(previsaoReceita1, this.getIdPanel());
      this.addPrevisaoItemReceita(previsaoReceita1, cont, cont.id);
      cont = this.addFonteMercado(previsaoReceita2, this.getIdPanel());
      this.addPrevisaoItemReceita(previsaoReceita2, cont, cont.id);
      this.closeLoading();
    });
  };

  addPrevisaoReceitaDIGITAL = async () => {
    await API.get("rest/pessoa/Cliente?id=29587&isUnidadeSenai=true&isAtivo=true").then((result) => {
      //SENAI DN
      let previsaoReceita1 = new PrevisaoReceitaModel();
      let previsaoReceita2 = new PrevisaoReceitaModel();
      this.state.tipoPrevisaoReceitaList.forEach((eachTipoPrevisaoReceita) => {
        if (eachTipoPrevisaoReceita.descricao === "SISTEMA INDÚSTRIA") previsaoReceita1.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
        if (eachTipoPrevisaoReceita.descricao === "MERCADO") previsaoReceita2.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
      });
      previsaoReceita1.isFonteReceita = true;
      previsaoReceita1.sistemaIndustria = result.data[0];
      previsaoReceita1.valor = 6000;
      previsaoReceita2.isFonteReceita = true;
      previsaoReceita2.valor = 8850;
      let cont = this.addSistemaIndustria(previsaoReceita1, this.getIdPanel());
      this.addPrevisaoItemReceita(previsaoReceita1, cont, cont.id);
      cont = this.addFonteMercado(previsaoReceita2, this.getIdPanel());
      this.addPrevisaoItemReceita(previsaoReceita2, cont, cont.id);
      this.closeLoading();
    });
  };

  addPrevisaoReceitaBrasilMaisLEAN = async () => {
    let previsaoReceita = new PrevisaoReceitaModel();
    this.state.tipoPrevisaoReceitaList.forEach((eachTipoPrevisaoReceita) => {
      if (eachTipoPrevisaoReceita.descricao === "MERCADO") previsaoReceita.tipoPrevisaoReceita = eachTipoPrevisaoReceita;
    });
    previsaoReceita.isFonteReceita = true;
    previsaoReceita.valor = 2400;
    previsaoReceita.fontePagadora = this.state.atendimento.cliente;
    previsaoReceita.enableValor = true;
    let cont = this.addFonteMercado(previsaoReceita, this.getIdPanel());
    this.addPrevisaoItemReceita(previsaoReceita, cont, cont.id);
    this.closeLoading();
  };

  changeTipoIPEA = (e) => {
    this.showLoading();
    let atendimento = this.state.atendimento;
    this.isAtendimentoIPEA = true;
    atendimento.isEmRede = false;
    atendimento.previsaoProducaoEmRedeList = [];
    atendimento["tipoBPIpea"] = e;
    if (atendimento.tipoBPIpea === "CONSULTORIA") {
      atendimento.numeroDeReceitaEstimada = 18000;
      atendimento.numeroDeProducaoEstimada = 120;
    } else if (atendimento.tipoBPIpea === "DIAGNOSTICO") {
      atendimento.numeroDeReceitaEstimada = 1800;
      atendimento.numeroDeProducaoEstimada = 12;
    }
    this.setState({ atendimento: atendimento });
    if (!this.state.isParceiroRede) this.setState({ atendimento: atendimento, previsaoReceitaList: [], content: [] });

    this.addPrevisaoReceitaIPEA();
  };

  habilitarDesabilitarCampos = (e, idPanel, fonte, prop, valor) => {
    if (!e.target.checked) {
      this.changeValorFonte("", valor, idPanel, prop);
    }

    fonte[`${prop}`] = e.target.checked;
    let cont = this.refreshContentCollapse(fonte, idPanel);
    let index = this.state.content.findIndex((cont) => {
      return cont.id === idPanel;
    });

    if (index > -1) {
      let contentList = this.state.content;
      contentList[index] = cont;
      this.setState({ content: contentList });
    }
  };

  getResourcesFields(fonte, idPanel, checkValor, checkContEcon, checkContFianc) {
    let flagFinanceira =
      this.state.atendimento.isValorHora ||
      this.state.isParceiroRede ||
      this.isAtendimentoBP ||
      this.isAtendimentoIPEA ||
      this.isAtendimentoIE ||
      this.isAtendimentoIA180 ||
      this.isAtendimentoDIGITAL ||
      this.isAtendimentoLEAN() ||
      fonte.tipoPrevisaoReceita.id === 10 ||
      fonte.tipoPrevisaoReceita.id === 12;
    let flagCFinanceira =
      this.state.atendimento.isValorHora ||
      this.state.isParceiroRede ||
      this.isAtendimentoBP ||
      this.isAtendimentoBrasilMaisLEAN ||
      this.isAtendimentoIPEA ||
      this.isAtendimentoIE ||
      this.isAtendimentoIA180 ||
      this.isAtendimentoDIGITAL ||
      this.isAtendimentoLEAN() ||
      fonte.tipoPrevisaoReceita.id === 8 ||
      this.isSte;
    let flagCEconomica =
      this.state.atendimento.isValorHora ||
      this.state.isParceiroRede ||
      this.isAtendimentoBP ||
      this.isAtendimentoBrasilMaisLEAN ||
      this.isAtendimentoIPEA ||
      this.isAtendimentoIE ||
      this.isAtendimentoIA180 ||
      this.isAtendimentoDIGITAL ||
      this.isAtendimentoLEAN() ||
      this.isSte ||
      fonte.tipoPrevisaoReceita.id === 8 ||
      fonte.tipoPrevisaoReceita.id === 7;
    return (
      <Grid container>
        <Grid item xs={9}>
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                defaultChecked={fonte.valor !== ""}
                onChange={(e) => {
                  this.habilitarDesabilitarCampos(e, idPanel, fonte, "enableValor", "valor");
                }}
                disabled={flagFinanceira || this.isAccepted}
                style={{ color: "#077CDC" }}
              />
            }
            label="Financeira"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={3}>
          <FormField
            type={"number"}
            changeValue={(e) => this.changeValorFonte(e.target.value, "valor", idPanel, "enableValor")}
            inputValue={fonte.valor !== "" ? fonte.valor : ""}
            disabled={flagFinanceira || !fonte.enableValor}
          />
        </Grid>
        <Grid item xs={9}>
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                defaultChecked={fonte.vlrfinanceiro !== ""}
                disabled={flagCFinanceira || this.isAccepted}
                onChange={(e) => {
                  this.habilitarDesabilitarCampos(e, idPanel, fonte, "enableFinanceiro");
                }}
                style={{ color: "#077CDC" }}
              />
            }
            label="Contrapartida Financeira"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={3}>
          <FormField
            type={"number"}
            changeValue={(e) => this.changeValorFonte(e.target.value, "vlrfinanceiro", idPanel, "enableFinanceiro")}
            inputValue={fonte.vlrfinanceiro !== "" ? fonte.vlrfinanceiro : ""}
            min={0}
            disabled={flagCFinanceira || !fonte.enableFinanceiro}
          />
        </Grid>
        <Grid item xs={9}>
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                defaultChecked={fonte.vlreconomico !== ""}
                disabled={flagCEconomica || this.isAccepted}
                onChange={(e) => {
                  this.habilitarDesabilitarCampos(e, idPanel, fonte, "enableEconomico");
                }}
                style={{ color: "#077CDC" }}
              />
            }
            label="Contrapartida Econômica"
            labelPlacement="end"
          />
        </Grid>
        <Grid item xs={3}>
          <FormField
            type={"number"}
            inputValue={fonte.vlreconomico !== "" ? fonte.vlreconomico : ""}
            changeValue={(e) => this.changeValorFonte(e.target.value, "vlreconomico", idPanel, "enableEconomico")}
            min={0}
            disabled={flagCEconomica || !fonte.enableEconomico}
          />
        </Grid>
      </Grid>
    );
  }

  async changeValorFonteRecurso(client, prop, idPanel) {
    let indexFont = this.state.previsaoReceitaList.findIndex((ft) => {
      return ft.idPanel === idPanel;
    });
    if (indexFont >= 0) {
      let list = this.state.previsaoReceitaList;
      list[indexFont][`${prop}`] = client;
      this.addPrevisaoItemReceita(list[indexFont], this.refreshContentCollapse(list[indexFont], idPanel), idPanel);
    }
  }

  refreshContentCollapse(font, idPanel) {
    if (font.tipoPrevisaoReceita) {
      switch (font.tipoPrevisaoReceita.id) {
        case 1:
          return this.addFonteMercado(font, idPanel);
        case 5:
          return this.addFonteICT(font, idPanel);
        case 7:
          return this.addFonteAgenciaNacional(font, idPanel);
        case 8:
          return this.addFonteAgenciaFomento(font, idPanel);
        case 10:
          return this.addUOAtendimento(font, idPanel);
        case 11:
          return this.addDRAtendimento(font, idPanel);
        case 12:
          return this.addSenaiParceiro(font, idPanel);
        case 13:
        case 14:
          return this.addSistemaIndustria(font, idPanel);
        // return this.addSesiSenaiDN(font, idPanel);
        default:
      }
    }
  }

  async changeValorFonte(valor, prop, id, enableField) {
    let font = this.state.previsaoReceitaList.find((ft) => {
      return ft.idPanel === id;
    });
    if (this.isAtendimentoBrasilMaisLEAN && valor != null && Number(valor) > 2400) valor = 2400;
    if (font) {
      font[`${prop}`] = valor ? Number(valor) : "";
      font[`${enableField}`] = true;
      let list = this.state.previsaoReceitaList;
      list.forEach((ft, index) => {
        if (ft.idPanel === id) {
          list[index] = font;
        }
      });

      let prevContent = this.refreshContentCollapse(font, id);
      this.addPrevisaoItemReceita(font, prevContent, id, true);
      this.calcularSomaReceitaProducao();
    }
  }

  createTemplateCliente(cliente) {
    return (
      <div className={"auto-complete-options"}>
        <div>
          <label>{cliente.nome}</label>
        </div>
        <div>
          <p>{cliente.razaoSocial}</p>
          <p>
            {cliente.cpfcnpj} - {cliente.enderecoList && cliente.enderecoList.length > 0 && cliente.enderecoList[0].municipio ? cliente.enderecoList[0].municipio.descricao : ""}
          </p>
        </div>
      </div>
    );
  }

  createTemplateProdutoRegional(produto) {
    return (
      <div className={"auto-complete-options"}>
        <div>
          <span className={"tipoUnidadeParametro"}>{produto.unidade.municipio.unidadeFederativa.sigla}</span>
          <label>{produto.nome}</label>
        </div>
        <div>
          <p>{produto.produtoNacional.descricao}</p>
          <p>
            {produto.produtoNacional.produtoCategoria.descricao} - {produto.produtoNacional.produtoCategoria.produtoLinha.descricao}
          </p>
        </div>
      </div>
    );
  }

  createTemplateUnidade(unidade) {
    return (
      <div className={"auto-complete-options"}>
        <div>
          <label>{unidade.abreviacao}</label>
        </div>
        <div>
          <p>
            {unidade.municipio && unidade.municipio.descricao} - {unidade.municipio && unidade.municipio.unidadeFederativa.sigla}
          </p>
        </div>
      </div>
    );
  }

  createTemplateAgenciaFomento(agenciaFomento) {
    return (
      <div className={"auto-complete-options"}>
        <div>
          <label>{agenciaFomento.descricao}</label>
        </div>
        <div>
          <p>{agenciaFomento.linhaFomento}</p>
        </div>
      </div>
    );
  }

  deletePanelItem = async (e) => {
    let contentList = this.state.content.filter((p) => {
      return p.id !== e;
    });
    let listFont = this.state.previsaoReceitaList.filter((p) => {
      return p.idPanel !== e;
    });
    await this.setState({ content: contentList, previsaoReceitaList: listFont });
    await this.calcularSomaReceitaProducao();
  };

  verificarPerfil(tipoPerfil) {
    return this.props.usuarioConectado.perfilUsuario.nome === tipoPerfil;
  }

  verificarProdutoBaseNacional(tipoProduto) {
    if (this.state.atendimento.produtoRegional.produtoNacional) {
      return this.state.atendimento.produtoRegional.produtoNacional.id === Number(tipoProduto);
    }
    return false;
  }

  isAtendimentoLEAN() {
    return (
      this.verificarProdutoBaseNacional(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_LEAN_28) ||
      this.verificarProdutoBaseNacional(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_LEAN_56) ||
      this.verificarProdutoBaseNacional(this.state.parametros.ID_PRODUTO_BRASIL_MAIS_PRODUTIVO_MENTORIA)
    );
  }

  updateProdutoLinha = (produtoLinha) => {
    let crProdutoLinha = produtoLinha.CR;
    switch (crProdutoLinha) {
      case "2": //PDI
        this.isInovacao = true;
        this.isConsultoria = false;
        this.isMetrologia = false;
        this.isSte = false;
        break;
      case "1": //CONSULTORIA
        this.isInovacao = false;
        this.isConsultoria = true;
        this.isMetrologia = false;
        this.isSte = false;
        break;
      case "3": //METROLOGIA
        this.isInovacao = false;
        this.isConsultoria = false;
        this.isMetrologia = true;
        this.isSte = false;
        break;
      case "4": //STE
        this.isInovacao = false;
        this.isConsultoria = false;
        this.isMetrologia = false;
        this.isSte = true;
        break;
      default:
        this.isInovacao = false;
        this.isConsultoria = false;
        this.isMetrologia = false;
        this.isSte = false;
        break;
    }
  };

  addParceiro(parc) {
    if (parc.id && parc.abreviacao && this.state.addParceiro === 3) {
      let prev = new PrevisaoProducaoEmRedeModel();
      prev.unidade = parc;
      if (!this.isParceiroInPrevisaoProducaoEmRedeList(prev)) {
        let list = this.state.parceiroList;
        list.push(prev);
        this.setState({ parceiroList: list });
      }
    }
    if (parc.id && parc.descricao && this.state.addParceiro === 5) {
      let prev = new PrevisaoReceitaModel();
      prev.ictParceiro = parc;
      prev.tipoPrevisaoReceita = { id: 5 };
      prev.detalhes = this.state.descricaoParceiro;
      if (!this.isParceiroInPrevisaoReceitaList(prev, "ictParceiro", 5)) {
        let list = this.state.parceiroList;
        list.push(prev);
        this.setState({ parceiroList: list, descricaoParceiro: "", parceiroICTAgencia: { descricao: "" } });
      }
    }
    if (parc.id && parc.descricao && this.state.addParceiro === 9) {
      let prev = new PrevisaoReceitaModel();
      prev.agenciaBolsa = parc;
      prev.tipoPrevisaoReceita = { id: 9 };
      prev.detalhes = this.state.descricaoParceiro;
      if (!this.isParceiroInPrevisaoReceitaList(prev, "agenciaBolsa", 9)) {
        let list = this.state.parceiroList;
        list.push(prev);
        this.setState({ parceiroList: list, descricaoParceiro: "", parceiroICTAgencia: { descricao: "" } });
      }
    }
  }

  isParceiroInPrevisaoReceitaList(parc, prop, tipo) {
    let isInPrevisaoReceita = this.state.previsaoReceitaList.find((item) => {
      return item instanceof PrevisaoReceitaModel && item.tipoPrevisaoReceita.id === tipo && item[`${prop}`].id === parc.id;
    });
    let isInParceiros = this.state.parceiroList.find((item) => {
      return item instanceof PrevisaoReceitaModel && item.tipoPrevisaoReceita.id === tipo && item[`${prop}`].id === parc.id;
    });
    return isInPrevisaoReceita || isInParceiros;
  }

  isParceiroInPrevisaoProducaoEmRedeList(parc) {
    let isInParceiros = this.state.parceiroList.find((item) => {
      return item instanceof PrevisaoProducaoEmRedeModel && parc.unidade.id === item.unidade.id;
    });
    let isProducaoRede = this.state.previsaoReceitaList.find((item) => {
      return item instanceof PrevisaoProducaoEmRedeModel && item.tipoPrevisaoReceita.id === this.state.addParceiro && item.unidade.id === parc.id;
    });
    return isInParceiros || isProducaoRede;
  }

  getReceita(tipoReceita) {
    let recurso = new PrevisaoReceitaModel();
    recurso.tipoPrevisaoReceita = { id: tipoReceita };
    if (this.state.atendimento.cliente != null && this.state.atendimento.cliente.id != null && this.state.atendimento.cliente.id > 0) {
      if (tipoReceita === 1 || tipoReceita === 13) recurso.fontePagadora = this.state.atendimento.cliente;
      else if (tipoReceita === 10) recurso.unidade = this.state.atendimento.unidade;
      else if (tipoReceita === 11) recurso.unidade = this.state.atendimento.unidade && this.state.atendimento.unidade.id > 0 ? this.state.atendimento.unidade.unidadePai : null;
    }
    return recurso;
  }

  deleteParceiro(parc, index) {
    let list = this.state.parceiroList;
    list.splice(index, 1);
    this.setState({ parceiroList: list });
    this.calcularSomaReceitaProducao();
  }

  setParceiro() {
    if (this.state.atendimento.unidade.id) {
      let indexParceiro = this.state.parceiroList.findIndex((par) => {
        return par instanceof PrevisaoProducaoEmRedeModel && par.unidade.id && par.unidade.id === this.state.atendimento.unidade.id;
      });
      if (indexParceiro < 0 && !this.props.idAtendimento) {
        let recUnid = new PrevisaoProducaoEmRedeModel();
        recUnid.unidade = this.state.atendimento.unidade;
        let list = this.state.parceiroList;
        list.splice(0, 0, recUnid);
        this.setState({ parceiroList: list });
      }
      if (indexParceiro > 0) {
        let list = this.state.parceiroList;
        let recUnid = list[indexParceiro];
        list.splice(indexParceiro, 1);
        list.splice(0, 0, recUnid);
        this.setState({ parceiroList: list });
      }
    }
  }

  isParceiroBase(parc) {
    return parc.unidade.id === this.state.atendimento.unidade.id;
  }

  async calcularSomaReceitaProducao(fontesRecursos = this.state.fontesRecursos) {
    let sumProducaoParceiro = 0;
    let sumReceitaParceiro = 0;
    let valor = fontesRecursos.length === 0 ? parseInt(this.state.valorFonteFinanceiro, 10) : 0;
    let economico = fontesRecursos.length === 0 ? parseInt(this.state.valorFonteEconomico, 10) : 0;
    fontesRecursos.forEach((font) => {
      if (font.valor) valor = valor + font.valor;
      if (font.economico) economico = economico + font.economico;
    });
    for (let i = 1; i < this.state.parceiroList.length; i++) {
      if (this.state.parceiroList[i] instanceof PrevisaoReceitaModel) {
        sumReceitaParceiro = sumReceitaParceiro + this.state.parceiroList[i].receitarepassada;
      } else {
        sumReceitaParceiro = sumReceitaParceiro + this.state.parceiroList[i].numeroDeReceitaEstimada;
        sumProducaoParceiro = sumProducaoParceiro + this.state.parceiroList[i].numeroDeProducaoEstimada;
      }
    }
    let list = this.state.parceiroList;
    // verificar se esses campos precisam de mudanças
    this.changeInputState(valor, "numeroDeReceitaEstimada");
    this.changeInputState(economico, "vlrEconomico");
    if (list != null && list.length > 0) {
      list[0].numeroDeProducaoEstimada = this.state.atendimento.numeroDeProducaoEstimada - sumProducaoParceiro;
      list[0].numeroDeReceitaEstimada = valor - sumReceitaParceiro;
    }
    let producaoTotalPrevista = list.length > 0 ? list[0].numeroDeProducaoEstimada + sumProducaoParceiro : 0;

    if (this.isAtendimentoBrasilMaisLEAN) {
      producaoTotalPrevista = this.state.atendimento.numeroDeProducaoEstimada;
    }

    if (this.state.isAtendimentoBMP) {
      await this.changeInputState(producaoTotalPrevista, "producaoTotalPrevista");
    }

    await this.setState({
      parceiroList: list,
      producaoTotalPrevista: producaoTotalPrevista,
      valorFinanceiro: valor,
      valorEconomico: economico,
    });
  }

  getLinesTable(isInsideModal) {
    this.setParceiro();
    let list = this.state.parceiroList.map((item, index) => {
      return this.createLine(item, index, isInsideModal);
    });
    return list;
  }

  changeValorParceiro(valor, index, prop) {
    let list = this.state.parceiroList;
    list[index][`${prop}`] = Number(valor);
    this.setState({ parceiroList: list });
    this.calcularSomaReceitaProducao();
  }

  createLine(obj, index, isInsideModal) {
    let receitaTotal = this.state.fontesRecursos.reduce(function (prev, curr) {
      return prev + (parseFloat(curr.valor) || 0);
    }, 0);
    if (index === 0) {
      let parceiros = this.state.parceiroList.slice(1);
      let somaParcReceita = parceiros.reduce(function (prev, curr) {
        if (curr instanceof PrevisaoReceitaModel) {
          return prev + curr.receitarepassada;
        }

        return prev + curr.numeroDeReceitaEstimada;
      }, 0);
      obj.numeroDeReceitaEstimada = receitaTotal - somaParcReceita;
    }
    if (obj instanceof PrevisaoProducaoEmRedeModel) {
      return [
        obj.unidade.descricao,
        !isInsideModal && (
          <FormField
            type={"number"}
            changeValue={(e) => {
              if (index === 0) this.changeInputState(Number(e.target.value), "numeroDeProducaoEstimada");
              this.changeValorParceiro(e.target.value, index, "numeroDeProducaoEstimada");
            }}
            disabled={this.state.atendimento.isEscopoIndefinido}
            inputValue={obj.numeroDeProducaoEstimada}
            min={0}
            maxLength="15"
          />
        ),
        !isInsideModal && !this.isParceiroBase(obj) ? (
          <FormField
            type={"number"}
            changeValue={(e) => this.changeValorParceiro(e.target.value, index, "numeroDeReceitaEstimada")}
            inputValue={obj.numeroDeReceitaEstimada}
            min={0}
            maxLength="15"
            disabled={this.isParceiroBase(obj)}
            subtype={"duascasas"}
          />
        ) : !isInsideModal ? (
          <div className={"formField input"}>
            <input disabled className={"formField"} type="text" value={obj.numeroDeReceitaEstimada.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} />
          </div>
        ) : null,
        <GenericButton
          color={"transparent"}
          click={(e) => {
            this.deleteParceiro(obj, index);
          }}
          subClass={"icon-and-button"}
          icon={"trash.png"}
          disabled={index === 0}
        />,
      ];
    } else if (obj instanceof PrevisaoReceitaModel && obj.tipoPrevisaoReceita.id === 5) {
      return [
        obj.ictParceiro.descricao,
        "",
        !isInsideModal && (
          <FormField
            type={"number"}
            changeValue={(e) => this.changeValorParceiro(e.target.value, index, "receitarepassada")}
            inputValue={obj.receitarepassada}
            min={0}
            maxLength="15"
            subtype={"duascasas"}
          />
        ),
        <GenericButton
          color={"transparent"}
          click={(e) => {
            this.deleteParceiro(obj, index);
          }}
          subClass={"icon-and-button"}
          icon={"trash.png"}
        />,
      ];
    } else if (obj instanceof PrevisaoReceitaModel && obj.tipoPrevisaoReceita.id === 9) {
      return [
        obj.agenciaBolsa.descricao,
        "",
        !isInsideModal && (
          <FormField
            type={"number"}
            changeValue={(e) => this.changeValorParceiro(e.target.value, index, "receitarepassada")}
            inputValue={obj.receitarepassada}
            min={0}
            maxLength="15"
            subtype={"duascasas"}
          />
        ),
        <GenericButton
          color={"transparent"}
          click={(e) => {
            this.deleteParceiro(obj, index);
          }}
          subClass={"icon-and-button"}
          icon={"trash.png"}
        />,
      ];
    }
  }

  isItemReceitaInList(previsao) {
    let item = this.state.previsaoReceitaList.find((item) => {
      return item.tipoPrevisaoReceita.id === previsao.tipoPrevisaoReceita.id;
    });
    return item;
  }

  isFontePagadora(fonte) {
    if (fonte.tipoPrevisaoReceita && fonte.tipoPrevisaoReceita.id !== 3 && fonte.tipoPrevisaoReceita.id !== 5 && fonte.tipoPrevisaoReceita.id !== 9) {
      return true;
    }
    if (fonte.valor > 0 || fonte.vlrfinanceiro > 0 || fonte.vlreconomico > 0) {
      return true;
    }
    return false;
  }

  async addPrevisaoItemReceita(previsao, previsaoContent, idPanel, refresh) {
    if (!refresh && (previsao.tipoPrevisaoReceita.id === 10 || previsao.tipoPrevisaoReceita.id === 11)) {
      let check = this.state.previsaoReceitaList.some((prev) => {
        return prev.tipoPrevisaoReceita.id === previsao.tipoPrevisaoReceita.id;
      });
      if (check) {
        return;
      }
    }

    let indexPrevReceita = this.state.previsaoReceitaList.findIndex((pr) => {
      return pr.idPanel === idPanel;
    });

    if (indexPrevReceita > -1) {
      let list = this.state.previsaoReceitaList;
      previsao.idPanel = previsaoContent.id;
      list[indexPrevReceita] = previsao;
      this.setState({ previsaoReceitaList: list });
    } else {
      let list = this.state.previsaoReceitaList;
      previsao.idPanel = previsaoContent.id;
      list.push(previsao);
      this.setState({ previsaoReceitaList: list });
    }

    let indexContent = this.state.content.findIndex((ct) => {
      return ct.id === idPanel;
    });

    if (indexContent > -1) {
      let content = this.state.content;
      content[indexContent] = previsaoContent;
      this.setState({ content: content });
    } else {
      let content = this.state.content;
      content.push(previsaoContent);
      this.setState({ content: content });
    }
    this.calcularSomaReceitaProducao();
  }

  deletePrevisaoItemReceita(previsao) {
    let list = this.state.previsaoReceitaList.map((item) => {
      return item.id !== previsao.id;
    });
    this.setState({ previsaoReceitaList: list });
  }

  formatData(date) {
    if (date) {
      if (typeof date !== "string") {
        let dataList = date.toLocaleDateString().split("/");
        return dataList[2] + "-" + dataList[1] + "-" + dataList[0];
      } else {
        if (date.length > 10) {
          var dataList = date.split("T");
          return dataList[0];
        } else {
          return date;
        }
      }
    }
  }

  formatDataCheckout(date) {
    if (date) {
      if (typeof date !== "string") {
        let dataList = date.toLocaleDateString().split("/");
        return dataList[0] + "/" + dataList[1] + "/" + dataList[2];
      } else {
        if (date.length > 10) {
          return new Date(date).toLocaleDateString("pt-br");
        } else {
          return date;
        }
      }
    }
  }

  statusAceito(atendimento) {
    this.isAccepted = atendimento.atendimentoStatus.chave === "aceito" || atendimento.atendimentoStatus.chave === "emexecucao";
  }

  validateAtendimento = (step) => {
    switch (step) {
      case 0:
        return this.validatePrimeiroPasso();
      case 1:
        return this.validateSegundoPasso();
      case 2:
        return this.validateTerceiroPasso();
      case 3:
        return this.validateQuartoPasso();
      case 4:
        return this.validateQuintoPasso();
      default:
        return true;
    }
  };

  validateDates = () => {
    if (!this.state.atendimento.dataInicioPrevista || !this.state.atendimento.dataConclusaoPrevista) {
      return false;
    }
    if (this.formatData(this.state.atendimento.dataInicioPrevista) > this.formatData(this.state.atendimento.dataConclusaoPrevista)) {
      return false;
    }
    return true;
  };

  validatePrimeiroPasso = () => {
    if (["STE", "Serviços Complementares"].includes(this.linhaAtendimentoOptions[this.state.linhaAtendimento])) {
      if (!this.state.linhaAtendimento || !this.state.atendimento.produtoRegional.nome) {
        return false;
      }
      return true;
    }

    if (!this.state.linhaAtendimento || !this.state.atendimento.produtoRegional.nome || !this.state.atendimento.atendimentoPesquisa.tipoVenda) {
      return false;
    }

    if (!this.state.atendimento.unidade.id) {
      return false;
    }
    /*
          if (!this.state.atendimento.produtoRegional.id || this.state.atendimento.produtoRegional.id <= 0) {
              return false;
          }
  
          if (!this.validateDates()) {
              return false;
          }
          if (this.isInovacao) {
              if (!this.state.atendimento.complexidade || !this.state.atendimento.grauInovacao
                  || !this.state.atendimento.abrangenciaImpacto || !this.state.atendimento.origemacaoprospectiva
                  || !this.state.atendimento.responsavelacaoprospectiva) {
                  return false;
              }
          }
          if (this.verificarPerfil('B+P') || this.isAtendimentoLEAN() || this.isAtendimentoBP) {
              if (!this.state.atendimento.setor || this.state.atendimento.setor.id <= 0 ||
                  !this.state.atendimento.apl || this.state.atendimento.apl.id <= 0) {
                  return false;
              }
          }
          if (this.verificarPerfil('IPEA') || this.isAtendimentoIPEA) {
              if (!this.state.atendimento.tipoBPIpea || !this.state.atendimento.setor || this.state.atendimento.setor.id <= 0 ||
                  !this.state.atendimento.apl || this.state.atendimento.apl.id <= 0) {
                  return false;
              }
          }
          if (this.verificarPerfil('I+E') || this.isAtendimentoIE || this.isAtendimentoDIGITAL || this.isAtendimentoBrasilMaisLEAN) {
              if (!this.state.atendimento.setor || this.state.atendimento.setor.id <= 0) {
                  return false;
              }
          }*/
    return true;
  };

  validateSegundoPasso = () => {
    if (this.linhaAtendimentoOptions[this.state.linhaAtendimento] == "PDI") {
      if (
        !this.state.atendimento.resumoExecutivo ||
        this.state.atendimento.resumoExecutivo.trim().length === 0 ||
        !this.state.atendimento.resumoExecutivo.trim().length > 500 ||
        !this.state.atendimento.titulo ||
        this.state.atendimento.titulo.trim().length === 0 ||
        this.state.atendimento.titulo.length > 500 ||
        !!!this.state.atendimento.areaPesquisaList.length ||
        !this.state.atendimento.dataInicioPrevista ||
        !this.state.atendimento.dataConclusaoPrevista
      ) {
        return false;
      }
    } else {
      if (
        !this.state.atendimento.titulo ||
        this.state.atendimento.titulo.trim().length === 0 ||
        this.state.atendimento.titulo.length > 500 ||
        !this.state.atendimento.dataInicioPrevista ||
        !this.state.atendimento.dataConclusaoPrevista
      ) {
        return false;
      }
    }

    return true;
  };

  validateTerceiroPasso = () => {
    if (
      !this.state.atendimento.cliente ||
      !this.state.atendimento.cliente.id ||
      this.state.atendimento.cliente.id === 0 ||
      this.state.atendimento.setor.id === 0 ||
      this.state.atendimento.setor === "" ||
      this.state.emOutroAtendimentoBMP === true ||
      this.state.isAtendimentoBMPSemPorte === true
    ) {
      return false;
    }
    return true;
  };

  validateQuartoPasso = () => {
    if (this.state.fontesRecursos.length === 0) {
      return false;
    }
    // else if (this.state.previsaoReceitaList.length === 1 && this.isAtendimentoLEAN() &&
    //         !(this.isAtendimentoMENTORIA && (this.props.usuarioConectado.unidade.id === 8 || this.props.usuarioConectado.unidade.unidadePai.id === 8))) {
    //         return false;
    //     }
    //     let checkFontes = this.state.previsaoReceitaList.some(font => {
    //         return this.getIdFonte(font) === undefined || this.getIdFonte(font) === 0;
    //     });
    //     if (checkFontes) {
    //         return false;
    //     }
    //     let checkValores = this.state.previsaoReceitaList.some(font => {
    //         return font.valor === '' && font.vlrfinanceiro === '' && font.vlreconomico === '';
    //     });
    //     if (checkValores) {
    //         return false;
    //     }
    //     if (this.isAtendimentoBrasilMaisLEAN && this.state.previsaoReceitaList != null && this.state.previsaoReceitaList.length > 0 && this.state.previsaoReceitaList[0].valor > 2400) {
    //         return false;
    //     }
    return true;
  };

  validateQuintoPasso = () => {
    // TODO: verificar qual a validação do 5º passso para metrologia
    if (this.linhaAtendimentoOptions[this.state.linhaAtendimento] == "Metrologia") return true;

    if (!this.state.producaoBMPConforme && this.state.isAtendimentoBMP) return false;

    if (this.state.parceiroList.length === 1 && this.state.atendimento.isEscopoIndefinido) {
      return true;
    } else {
      if (this.state.parceiroList.length < 2 && this.state.atendimento.numeroDeProducaoEstimada === 0) {
        return false;
      }

      if (this.state.parceiroList.length === 1 && !this.state.atendimento.isEscopoIndefinido && this.state.atendimento.numeroDeProducaoEstimada === 0) {
        return false;
      }

      let checkParceiro = this.state.parceiroList.some((parceiro) => {
        return parceiro instanceof PrevisaoProducaoEmRedeModel && parceiro.numeroDeProducaoEstimada < 0;
      });
      if (checkParceiro) {
        return false;
      }
      checkParceiro = this.state.parceiroList.some((parceiro) => {
        return (
          parceiro instanceof PrevisaoProducaoEmRedeModel &&
          parceiro.unidade.id !== this.state.atendimento.unidade.id &&
          (parceiro.numeroDeProducaoEstimada <= 0 || parceiro.numeroDeReceitaEstimada <= 0)
        );
      });
      if (checkParceiro) {
        return false;
      }

      return true;
    }
  };

  getTextoProducaoPrevista() {
    var rtno = "Relatórios";
    try {
      if (
        typeof this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeProducaoEstimada !== "undefined" &&
        this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeProducaoEstimada !== ""
      ) {
        rtno = this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeProducaoEstimada;
      }
    } catch (e) {}
    return rtno;
  }

  getTextoNumeroRelatorio() {
    //Certificados ou Calibrações
    var rtno = "Certificados";
    try {
      if (
        typeof this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeRelatorioEstimado !== "undefined" &&
        this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeRelatorioEstimado !== ""
      ) {
        rtno = this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.labelNumeroDeRelatorioEstimado;
      }
    } catch (e) {}
    return rtno;
  }

  async getProdutoRegional() {
    if (this.verificarPerfil("B+P")) {
      let produtoList = (await API.get(`/rest/auxiliar/ProdutoRegional?isAtivo=true&produtoNacional.id=193&unidade.id=${this.props.usuarioConectado.unidade.id}`)).data;
      if (produtoList.length > 0) {
        this.changeInputState(produtoList[0], "produtoRegional");
      }
    }
  }

  clearProducao() {
    this.changeInputState(0, "numeroDeProducaoEstimada");
    this.changeInputState(0, "numeroDeRelatorioEstimado");
    let list = this.state.parceiroList.map((e) => {
      return (e.numeroDeProducaoEstimada = 0);
    });
    this.setState({ parceiroList: list });
    this.calcularSomaReceitaProducao();
  }

  closeModalCliente = (cliente) => {
    let atd = this.state.atendimento;
    if (cliente != null && cliente.id != null) {
      atd.cliente = cliente;
      this.updateSetoresPorCnae(atd);
      this.setState({ openModal: false, atendimento: atd });
    }
  };

  getStep = (step) => {
    if (
      step === 2 &&
      (this.isAtendimentoBP ||
        this.isAtendimentoIA ||
        this.isAtendimentoIPEA ||
        this.isAtendimentoLEAN28 ||
        this.isAtendimentoLEAN56 ||
        this.isAtendimentoMENTORIA ||
        this.isAtendimentoIE ||
        this.isAtendimentoIA180 ||
        this.isAtendimentoDIGITAL ||
        this.isAtendimentoBrasilMaisLEAN ||
        this.state.isParceiroRede)
    ) {
      //RECURSOS
      this.calcularSomaReceitaProducao();
    }
  };

  changeLinhaAtendimento = (e) => {
    let atendimento = this.state.atendimento;
    if (this.state.linhaAtendimento != e.target.value) atendimento.produtoRegional = new AtendimentoModel().produtoRegional;
    this.setState({ linhaAtendimento: parseInt(e.target.value), atendimento: atendimento });
  };

  changeRadioValueTipoFontePagadora = (e) => {
    this.setState({ radioTipoFontePagadora: e.target.value, valorFonteFinanceiro: 0, valorFonteEconomico: 0, fontePagadora: null });
    this.setState((prevState) => ({ atendimento: { ...prevState.atendimento, fonte: "" } }));
    if (e.target.value === "mercado") {
      this.setState({ radioTipoFonte: "", autoCompleteFontePath: "/rest/pessoa/Cliente/0/30?isAtivo=true&isUnidadeSenai=false&tipoCnpj.id=3&buscaLivre=" });
    } else if (e.target.value === "fomento-externo") {
      this.setState({ radioTipoFonte: "", autoCompleteFontePath: "/rest/gestao/AgenciaFomento?descricao=%" });
    } else {
      this.setState({ radioIsICT: "", radioIsReguladosIsento: "", autoCompleteFontePath: "" });
    }
  };

  changeValoresFontes = (e) => {
    let valorFonte = {};
    if (e.target.id === "financeiro") valorFonte = { valorFonteFinanceiro: e.target.value };
    else valorFonte = { valorFonteEconomico: e.target.value };
    this.setState(valorFonte);
  };

  changeRadioValueTipoParceria = (e) => {
    if (e.target.value === "senai") {
      this.setState({ radioTipoParceria: e.target.value, addParceiro: 3, descricaoParceiro: "" });
    }
    if (e.target.value === "ict") {
      this.setState({ radioTipoParceria: e.target.value, addParceiro: 5, descricaoParceiro: "" });
    }
  };

  validateIsICT = (e) => {
    let idTipoCnpj = e.target.value === "true" ? 2 : 3;
    const preventICTChange = e.target.value === "true" ? "" : "true";
    this.setState({
      radioIsICT: e.target.value,
      autoCompleteFontePath: `/rest/pessoa/Cliente/0/30?isAtivo=true&isUnidadeSenai=false&tipoCnpj.id=${idTipoCnpj}&buscaLivre=`,
      fontePagadora: null,
      radioIsReguladosIsento: preventICTChange,
      recursoRegulado: e.target.value === "true" ? this.state.recursoRegulado : null,
    });
  };

  validateIsReguladoIsento = (e) => {
    this.setState({ radioIsReguladosIsento: e.target.value, recursoRegulado: null });
  };

  getSenaiDn = async () => {
    const { data } = await API.get(`/rest/pessoa/Cliente/0/30?cpfcnpj=33564543000190`);

    if (data) {
      const fontePagadora = data[0];

      fontePagadora.descricao = fontePagadora.razaoSocial;

      this.setState({ fontePagadora });
    }
  };

  validateTipoFonte = async (e) => {
    let autoCompletePath = "";
    let fontePagadora = null;
    if (e.target.value === "uo-atendimento") {
      let filter = this.isUO ? `id=${this.props.usuarioConectado.unidade.id}` : `unidadePai.id=${this.props.usuarioConectado.unidade.id}`;
      autoCompletePath = `/rest/gestao/Unidade/0/30?${filter}&buscaLivre=`;
      if (this.isUO) fontePagadora = this.props.usuarioConectado.unidade;
    } else if (e.target.value === "dr-atendimento") {
      let filter = this.isUO ? `id=${this.props.usuarioConectado.unidade.unidadePai.id}` : `id=${this.props.usuarioConectado.unidade.id}`;
      autoCompletePath = `/rest/gestao/Unidade/0/30?${filter}&buscaLivre=`;
      fontePagadora = this.isDR ? this.props.usuarioConectado.unidade : this.props.usuarioConectado.unidade.unidadePai;
    } else if (e.target.value === "uo-parceira") {
      autoCompletePath = "/rest/gestao/Unidade/0/30?especialidadeUnidade.id=1&especialidadeUnidade.id=2&especialidadeUnidade.id=3&tipoUnidade.id=2&buscaLivre=";
    } else if (e.target.value === "outros-sistemas") {
      autoCompletePath = "/rest/pessoa/Cliente/0/30?isAtivo=true&isUnidadeSenai=true&buscaLivre=";
    } else if (e.target.value === "sesi-senai-dn") {
      autoCompletePath = `/rest/pessoa/Cliente/0/30?cpfcnpj=33564543000190`;
      this.getSenaiDn();
    }
    this.setState({
      radioTipoFonte: e.target.value,
      autoCompleteFontePath: autoCompletePath,
      fontePagadora: fontePagadora,
    });
  };

  switchTemplateFontePagadora = (isTemplate = true) => {
    const isCliente = this.state.radioTipoFontePagadora === "mercado" || this.state.radioTipoFonte === "outros-sistemas" || this.state.radioTipoFonte === "sesi-senai-dn";
    if (isTemplate) return isCliente ? this.createTemplateCliente : this.state.radioTipoFontePagadora === "fomento-externo" ? this.createTemplateAgenciaFomento : this.createTemplateUnidade;
    return isCliente ? "nome" : "descricao";
  };

  canSetAutoCompleteFonte = () => {
    return (
      (this.isUO && (this.state.radioTipoFonte === "uo-atendimento" || this.state.radioTipoFonte === "dr-atendimento")) ||
      (this.isDR && this.state.radioTipoFonte === "dr-atendimento") ||
      this.state.radioTipoFonte === "sesi-senai-dn"
    );
  };

  clearModalAddFonte = () => {
    this.setState({
      radioTipoFontePagadora: "",
      fontePagadora: null,
      radioTipoFonte: "",
      valorFonteFinanceiro: 0,
      valorFonteEconomico: 0,
      radioIsReguladosIsento: "",
      recursoRegulado: null,
      radioIsICT: "",
    });
  };

  cantAdicionarFontes = () => {
    let condicao = true;
    let fontePagadora = this.state.radioTipoFontePagadora;
    if (fontePagadora === "mercado" || fontePagadora === "fomento-externo" || (fontePagadora === "sistema-indústria" && this.state.radioTipoFonte === "outros-sistemas"))
      condicao = this.state.valorFonteFinanceiro >= 0;
    else if (fontePagadora === "sistema-indústria" && this.state.radioTipoFonte !== "outros-sistemas") condicao = this.state.valorFonteEconomico >= 0;

    if (fontePagadora === "sistema-indústria" && this.state.radioTipoFonte === "sesi-senai-dn")
      condicao = this.state.valorFonteFinanceiro >= 0 && this.state.valorFonteEconomico >= 0 && this.state.atendimento.numeroProjetoSGF;

    if (fontePagadora === "sistema-indústria" && ["STE", "Serviços Complementares"].includes(this.linhaAtendimentoOptions[this.state.linhaAtendimento]))
      if (this.state.radioTipoFonte === "sesi-senai-dn") {
        condicao = this.state.valorFonteFinanceiro >= 0 && this.state.atendimento.numeroProjetoSGF;
      } else {
        condicao = this.state.valorFonteFinanceiro >= 0;
      }

    return !(condicao && this.state.fontePagadora && (this.state.radioIsReguladosIsento === "" || this.state.recursoRegulado));
  };

  createEditFonteRecurso = () => {
    let radioTipoFonte = this.state.radioTipoFonte;
    const indexPrevisaoReceita = this.state.fontesRecursos.findIndex((fonte) => fonte.randID === this.state.fonteRandID);
    let previsaoReceita = indexPrevisaoReceita !== -1 ? this.state.fontesRecursos[indexPrevisaoReceita] : new PrevisaoReceitaModel();
    if (!previsaoReceita.randID) {
      previsaoReceita.tipoFonte = radioTipoFonte;
      previsaoReceita.randID = Math.random();
    }
    previsaoReceita.valor = parseFloat(this.state.valorFonteFinanceiro);
    previsaoReceita.economico = parseFloat(this.state.valorFonteEconomico);
    previsaoReceita.tipoFontePagadora = this.state.radioTipoFontePagadora;
    if (this.state.radioTipoFontePagadora === "mercado") {
      let fontePagadora = this.state.fontePagadora;
      fontePagadora.agenciaReguladora = this.state.recursoRegulado ? { agenciaNacional: this.state.recursoRegulado } : null;
      previsaoReceita.fontePagadora = fontePagadora;
      previsaoReceita.tipoPrevisaoReceita = { id: 1 };
      previsaoReceita.agenciaFomento = null;
      previsaoReceita.unidade = null;
      previsaoReceita.sistemaIndustria = null;
    }
    if (this.state.radioTipoFontePagadora === "sistema-indústria") {
      previsaoReceita.tipoPrevisaoReceita = {
        id: radioTipoFonte === "uo-atendimento" ? 10 : radioTipoFonte === "dr-atendimento" ? 11 : radioTipoFonte === "sesi-senai-dn" ? 14 : radioTipoFonte === "uo-parceira" ? 12 : 13,
      };
      previsaoReceita.tipoFonte = radioTipoFonte;
      if (radioTipoFonte === "outros-sistemas") {
        previsaoReceita.fontePagadora = this.state.atendimento.cliente;
        previsaoReceita.sistemaIndustria = this.state.fontePagadora;
        previsaoReceita.agenciaFomento = null;
        previsaoReceita.unidade = null;
      } else if (radioTipoFonte === "sesi-senai-dn") {
        previsaoReceita.fontePagadora = this.state.atendimento.cliente;
        previsaoReceita.sistemaIndustria = this.state.fontePagadora;
        previsaoReceita.agenciaFomento = null;
        previsaoReceita.unidade = null;
      } else {
        previsaoReceita.unidade = this.state.fontePagadora;
        previsaoReceita.fontePagadora = null;
        previsaoReceita.agenciaFomento = null;
        previsaoReceita.sistemaIndustria = null;
      }
    }
    if (this.state.radioTipoFontePagadora === "fomento-externo") {
      previsaoReceita.tipoPrevisaoReceita = { id: 8 };
      previsaoReceita.agenciaFomento = this.state.fontePagadora;
      previsaoReceita.fontePagadora = null;
      previsaoReceita.unidade = null;
      previsaoReceita.sistemaIndustria = null;
    }
    if (indexPrevisaoReceita !== -1) {
      let fontesRecursos = this.state.fontesRecursos;
      fontesRecursos[indexPrevisaoReceita] = previsaoReceita;
      this.setState({ fontesRecursos: fontesRecursos, openModalFonte: false, fonteRandID: 0 });
      this.calcularSomaReceitaProducao(fontesRecursos);
    } else {
      this.setState({ fontesRecursos: [...this.state.fontesRecursos, previsaoReceita], openModalFonte: false, fonteRandID: 0 });
      this.calcularSomaReceitaProducao([...this.state.fontesRecursos, previsaoReceita]);
    }
    this.clearModalAddFonte();
  };

  closeParceiroModal = (e) => {
    this.setState({
      openModalParceria: false,
      radioTipoParceria: "",
    });
  };

  onTagsChange = (event, values) => {
    if (values.length <= 3) {
      this.setState((prevState) => ({ atendimento: { ...prevState.atendimento, areaPesquisaList: values } }));
    }
    if (values.length === 3) {
      this.setState({ isAreaPesquisaMaxOptions: true });
    }
  };

  removeTag = (tag) => {
    var array = [...this.state.atendimento.areaPesquisaList];
    var index = array.indexOf(tag);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState((prevState) => ({ atendimento: { ...prevState.atendimento, areaPesquisaList: array } }));
      if (array.length < 3) this.setState({ isAreaPesquisaMaxOptions: false });
    }
  };

  fillEditFonteRecurso = (fonteRecurso) => {
    const agenciaReguladora = fonteRecurso.fontePagadora ? fonteRecurso.fontePagadora.agenciaReguladora : null;
    const tipoCnpj = fonteRecurso.fontePagadora ? fonteRecurso.fontePagadora.tipoCnpj : null;
    this.setState({
      openModalFonte: true,
      fontePagadora:
        fonteRecurso.tipoPrevisaoReceita.id === 13 || fonteRecurso.tipoPrevisaoReceita.id === 14
          ? fonteRecurso.sistemaIndustria
          : fonteRecurso.tipoPrevisaoReceita.id === 1
          ? fonteRecurso.fontePagadora
          : [10, 11, 12].includes(fonteRecurso.tipoPrevisaoReceita.id)
          ? fonteRecurso.unidade
          : fonteRecurso.agenciaFomento,
      radioTipoFontePagadora: fonteRecurso.tipoPrevisaoReceita.id === 1 ? "mercado" : [10, 11, 12, 13, 14].includes(fonteRecurso.tipoPrevisaoReceita.id) ? "sistema-indústria" : "fomento-externo",
      radioTipoFonte:
        fonteRecurso.tipoPrevisaoReceita.id === 10
          ? "uo-atendimento"
          : fonteRecurso.tipoPrevisaoReceita.id === 11
          ? "dr-atendimento"
          : fonteRecurso.tipoPrevisaoReceita.id === 12
          ? "uo-parceira"
          : fonteRecurso.tipoPrevisaoReceita.id === 14
          ? "sesi-senai-dn"
          : "outros-sistemas",
      valorFonteFinanceiro: fonteRecurso.valor,
      valorFonteEconomico: fonteRecurso.economico,
      radioIsReguladosIsento: agenciaReguladora ? "true" : "",
      recursoRegulado: agenciaReguladora ? agenciaReguladora.agenciaNacional : null,
      radioIsICT: tipoCnpj && tipoCnpj.id === 2 ? "true" : "",
      fonteRandID: fonteRecurso.randID,
    });
  };

  removerFonteRecurso = (fonteRecurso) => {
    var array = [...this.state.fontesRecursos];
    let atendimento = this.state.atendimento;
    var index = array.indexOf(fonteRecurso);
    if (index !== -1) {
      array.splice(index, 1);
      atendimento.previsaoReceitaList = array;
      this.calcularSomaReceitaProducao(array);
      this.setState({ atendimento: atendimento, fontesRecursos: array });
    }
  };

  salvarNovoCliente = (cliente) => {
    const atendimento = this.state.atendimento;

    atendimento.cliente = cliente;

    this.setState({ novoCliente: cliente, atendimento }, this.changeCliente());

    this.updateSetoresPorCnae();
  };

  getClienteBackendNovo = async (cliente) => {
    try {
      if (cliente !== undefined) {
        const res = await newBackend.get(`/cliente/${cliente.id}`);
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  getAtendimentoByClienteProdutoRegional = async (idCliente, idProdutoRegional) => {
    try {
      const res = await newBackend.get(`atendimento/check-atendimento?idCliente=${idCliente}&idProdutoRegional=${idProdutoRegional}`);
      console.log(res);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  checkAtendimentoBMP = () => {
    const codigosDNBMP = [42120, 42128, 412159, 412160, 412161, 412162, 412163, 412164];
    const codigodnNumero = parseInt(this.state.atendimento.produtoRegional.produtoNacional.codigoDN);
    const programaBMP = codigosDNBMP.includes(codigodnNumero);
    if (programaBMP) {
      this.setState({ isAtendimentoBMP: true });
    } else {
      this.setState({ isAtendimentoBMP: false });
    }
  };

  checkPortesClientesBMP = async (cliente) => {
    if (this.state.isAtendimentoBMP) {
      const clienteBackNovo = await this.getClienteBackendNovo(cliente);
      if (clienteBackNovo.porte_cliente_rais === null || clienteBackNovo.porte_cliente_faturamento?.id === null) {
        this.setState({ isAtendimentoBMPSemPorte: true });
      } else {
        this.setState({ isAtendimentoBMPSemPorte: false });
      }
    }
  };

  getPorteClienteReceita = async () => {
    let idPorteCliente = this.state.atendimento.cliente?.porteCliente?.id;
    let porteCliente = this.state.atendimento.cliente?.porteCliente?.descricao;
    try {
      const cliente = await newBackend.get(`/busca-cnpj/novo/${this.state.atendimento.cliente.cpfcnpj}`);
      if (cliente && cliente.data) {
        const porteReceita = cliente.data.cliente.porte;
        const idPorteClienteReceita = porteClienteReceitaMapping[porteReceita];

        if (idPorteClienteReceita && porteReceita) {
          idPorteCliente = idPorteClienteReceita;
          porteCliente = porteReceita;
        }
      }
      this.setState({ idPorteClienteReceita: idPorteCliente, porteClienteReceita: porteCliente });
    } catch (error) {
      this.setState({ idPorteClienteReceita: idPorteCliente, porteClienteReceita: porteCliente });
      console.log("Erro ao buscar dados do cliente:", error);
    }
  };

  checkConstaEmOutroAtendimentoBMP = async () => {
    const cliente = this.state.atendimento.cliente;
    const produtoRegional = this.state.atendimento.produtoRegional;
    try {
      const checaClienteEProdutoRegional = await this.getAtendimentoByClienteProdutoRegional(cliente.id, produtoRegional.id);
      if (checaClienteEProdutoRegional) {
        this.setState({ emOutroAtendimentoBMP: true });
      } else {
        this.setState({ emOutroAtendimentoBMP: false });
      }
    } catch (error) {
      this.setState({ emOutroAtendimentoBMP: true });
    }
  };

  checkProducaoPorPorte = (producaoPrevista) => {
    const porteCliente = this.state.atendimento.cliente?.porteCliente?.id;

    const producaoEstimada = this.producaoPorPorte[porteCliente]?.[this.state.atendimento.produtoRegional.produtoNacional.id];

    if (producaoEstimada === producaoPrevista) {
      this.setState({ producaoBMPConforme: true });
    } else {
      this.setState({ producaoBMPConforme: false });
    }
  };

  getSteps() {
    return [
      /* Etapa - Informações gerais */
      <Grid container className={"step"}>
        <Grid item xs={12} md={9} lg={6} xl={5}>
          <Grid container>
            {(this.verificarPerfil("B+P") || this.verificarPerfil("I+A") || this.isDR) && (
              <Grid item xs={12}>
                <AutoComplete
                  itemKey={"id"}
                  key="autoCompleteUnidade"
                  label={"Unidade do atendimento"}
                  itemTemplate={this.createTemplateUnidade}
                  getInput={(e) => {
                    this.changeInputState(e, "unidade");
                  }}
                  itemlabel={"abreviacao"}
                  initialSelectedItem={this.state.atendimento.unidade}
                  path={
                    this.linhaAtendimentoOptions[this.state.linhaAtendimento] == "PDI"
                      ? `/rest/gestao/Unidade/0/30?tipoUnidade.sigla=UO&unidadePai.id=${this.props.usuarioConectado.unidade.id}&buscaLivre=`
                      : `/rest/gestao/Unidade/0/30?tipoUnidade.sigla=UO&unidadePai.id=${this.props.usuarioConectado.unidade.id}&buscaLivre=`
                  }
                  disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.verificarPerfil("B+P")}
                  defaultObject={true}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <label className="label-field">Escolha a linha de atendimento</label>
              <Grid className={"linha-atendimento"}>
                <RadioGroup row className={"radio-group"} value={this.state.linhaAtendimento} onChange={this.changeLinhaAtendimento}>
                  <FormControlLabel className={"radio"} value={3} control={<Radio />} label={"Metrologia"} />
                  <FormControlLabel className={"radio"} value={4} control={<Radio />} label={"STE"} />
                  <FormControlLabel className={"radio-Especial"} value={2} control={<Radio />} label={"PDI"} />
                  <FormControlLabel className={"radio"} value={1} control={<Radio />} label={"Consultoria"} />
                  <FormControlLabel className={"radio-Especial"} value={6} control={<Radio />} label={"Serviços complementares"} />
                </RadioGroup>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <AutoComplete
                placeholder={"Busque por Nome ou Categoria"}
                itemKey={"id"}
                initialSelectedItem={this.state.atendimento.produtoRegional}
                key="autoCompleteProdutoRegional"
                itemlabel={"nome"}
                label={"Produto Regional"}
                getInput={(e) => {
                  this.changeInputState(e, "produtoRegional");
                }}
                path={
                  `/rest/auxiliar/ProdutoRegional/0/50?isAtivo=true&produtoNacional.produtoCategoria.produtoLinha.id=${this.state.linhaAtendimento}` +
                  `&unidade.id=${this.isUO ? this.props.usuarioConectado.unidade.unidadePai.id : this.props.usuarioConectado.unidade.id}&buscaLivreProdutoRegional=`
                }
                itemTemplate={this.createTemplateProdutoRegional}
                disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.verificarPerfil("B+P")}
                defaultObject={true}
              />
            </Grid>

            {(this.verificarPerfil("B+P") || this.isAtendimentoLEAN() || this.isAtendimentoBP) && (
              <Grid container>
                <Grid item xs={6} className={"paddingForm"}>
                  <AutoComplete
                    placeholder={"Digite o Setor"}
                    itemKey={"id"}
                    initialSelectedItem={this.state.atendimento.setor}
                    key="autoCompleteSetor"
                    itemlabel={"descricao"}
                    label={"Setor"}
                    getInput={(e) => {
                      this.changeInputState(e, "setor");
                    }}
                    path={`/rest/gestao/Setor?produtobp=true&descricao=%`}
                    posPath={"%"}
                    disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                    defaultObject={true}
                    toUpperCase={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AutoComplete
                    placeholder={"Digite a APL"}
                    itemKey={"id"}
                    initialSelectedItem={this.state.atendimento.apl}
                    key="autoCompleteAPL"
                    itemlabel={"descricao"}
                    label={"APL"}
                    getInput={(e) => {
                      this.changeInputState(e, "apl");
                    }}
                    posPath={"%"}
                    path={`/rest/gestao/Apl?${
                      this.state.atendimento.setor && this.state.atendimento.setor.id && this.state.atendimento.setor.id > 0 ? `&setor.id=${this.state.atendimento.setor.id}` : ""
                    }&unidadeFederativa.id=${this.isUO ? this.props.usuarioConectado.unidade.unidadePai.id : this.props.usuarioConectado.unidade.id}&descricao=%`}
                    disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                    defaultObject={true}
                    toUpperCase={true}
                  />
                </Grid>
              </Grid>
            )}

            {(this.verificarPerfil("IPEA") || this.isAtendimentoIPEA) && (
              <Grid container>
                <Grid item xs={4} className={"paddingForm"}>
                  <InputSelect
                    key="tipo"
                    label={"Tipo"}
                    suggestions={["CONSULTORIA", "DIAGNOSTICO"]}
                    disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                    initialSelectedItem={this.state.atendimento.tipoBPIpea}
                    getInput={(e) => this.changeTipoIPEA(e)}
                  ></InputSelect>
                </Grid>
                <Grid item xs={4} className={"paddingForm"}>
                  <AutoComplete
                    placeholder={"Digite o Setor"}
                    itemKey={"id"}
                    initialSelectedItem={this.state.atendimento.setor}
                    posPath={"%"}
                    key="autoCompleteSetor"
                    itemlabel={"descricao"}
                    label={"Setor"}
                    getInput={(e) => {
                      this.changeInputState(e, "setor");
                    }}
                    path={`/rest/gestao/Setor?produtobp=true&descricao=%`}
                    disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                    defaultObject={true}
                    toUpperCase={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutoComplete
                    placeholder={"Digite a APL"}
                    itemKey={"id"}
                    initialSelectedItem={this.state.atendimento.apl}
                    key="autoCompleteAPL"
                    itemlabel={"descricao"}
                    label={"APL"}
                    getInput={(e) => {
                      this.changeInputState(e, "apl");
                    }}
                    posPath={"%"}
                    path={`/rest/gestao/Apl?${
                      this.state.atendimento.setor && this.state.atendimento.setor.id && this.state.atendimento.setor.id > 0 ? `&setor.id=${this.state.atendimento.setor.id}` : ""
                    }&unidadeFederativa.id=${this.isUO ? this.props.usuarioConectado.unidade.unidadePai.id : this.props.usuarioConectado.unidade.id}&descricao=%`}
                    disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                    defaultObject={true}
                    toUpperCase={true}
                  />
                </Grid>
              </Grid>
            )}

            {this.isAtendimentoDIGITAL && (
              <Grid container>
                <Grid item xs={6} className={"paddingForm"}>
                  <AutoComplete
                    placeholder={"Digite o Setor"}
                    itemKey={"id"}
                    initialSelectedItem={this.state.atendimento.setor}
                    posPath={"%"}
                    key="autoCompleteSetor"
                    itemlabel={"descricao"}
                    label={"Setor"}
                    getInput={(e) => {
                      this.changeInputState(e, "setor");
                    }}
                    path={`/rest/gestao/Setor?produtobp=true&descricao=%`}
                    disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                    defaultObject={true}
                    toUpperCase={true}
                  />
                </Grid>
              </Grid>
            )}

            {(this.verificarPerfil("I+E") || this.isAtendimentoIE) && (
              <Grid container>
                <Grid item xs={6} className={"paddingForm"}>
                  <AutoComplete
                    placeholder={"Digite o Setor"}
                    itemKey={"id"}
                    initialSelectedItem={this.state.atendimento.setor}
                    posPath={"%"}
                    key="autoCompleteSetor"
                    itemlabel={"descricao"}
                    label={"Setor"}
                    getInput={(e) => {
                      this.changeInputState(e, "setor");
                    }}
                    path={`/rest/gestao/Setor?produtoie=true&descricao=%`}
                    disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                    defaultObject={true}
                    toUpperCase={true}
                  />
                </Grid>
              </Grid>
            )}

            {this.isAtendimentoBrasilMaisLEAN && (
              <Grid container>
                <Grid item xs={6} className={"paddingForm"}>
                  <AutoComplete
                    placeholder={"Digite o Setor"}
                    itemKey={"id"}
                    initialSelectedItem={this.state.atendimento.setor ? this.state.atendimento.setor : ""}
                    posPath={"%"}
                    key="autoCompleteSetor"
                    itemlabel={"descricao"}
                    label={"Setor"}
                    getInput={(e) => {
                      this.changeInputState(e, "setor");
                    }}
                    path={`/rest/gestao/Setor?idproduto=${this.state.parametros.ID_PRODUTO_BRASIL_MAIS_MENTORIA_LEAN}&ordenarPor=descricao&descricao=%`}
                    disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                    defaultObject={true}
                    toUpperCase={true}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container className={"box-responsavel-e-opcional"}>
              <Grid>
                <InputSelect
                  key="tipoVenda"
                  label={`Responsável pela venda/propecção ${["STE", "Serviços Complementares"].includes(this.linhaAtendimentoOptions[this.state.linhaAtendimento]) ? "(Opcional)" : ""}`}
                  suggestions={this.state.tipoVenda}
                  itemLabel={"descricao"}
                  itemKey={"id"}
                  initialSelectedItem={
                    this.state.atendimento.atendimentoPesquisa && this.state.atendimento.atendimentoPesquisa.tipoVenda ? this.state.atendimento.atendimentoPesquisa.tipoVenda : { id: 0, descricao: "" }
                  }
                  disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                  getInput={(e) => {
                    this.changeAtendimentoPesquisa(e, "tipoVenda");
                  }}
                ></InputSelect>
              </Grid>
              <Grid>
                <InputSelect
                  key="tipoProduto"
                  label={"O cliente nos conheceu através de (Opcional)"}
                  suggestions={this.state.tipoProduto}
                  itemLabel={"descricao"}
                  itemKey={"id"}
                  initialSelectedItem={
                    this.state.atendimento.atendimentoPesquisa && this.state.atendimento.atendimentoPesquisa.tipoProduto
                      ? this.state.atendimento.atendimentoPesquisa.tipoProduto
                      : { id: 0, descricao: "" }
                  }
                  disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                  getInput={(e) => {
                    this.changeAtendimentoPesquisa(e, "tipoProduto");
                  }}
                ></InputSelect>
              </Grid>

              {this.state.atendimento.atendimentoPesquisa && this.state.atendimento.atendimentoPesquisa.tipoProduto && this.state.atendimento.atendimentoPesquisa.tipoProduto.id === 14 && (
                <Grid item xs={12}>
                  <FormField
                    type={"textarea"}
                    label={"Qual?"}
                    changeValue={(e) => {
                      let atendimentoPesquisa = this.state.atendimento.atendimentoPesquisa;
                      atendimentoPesquisa.detalhes = e.target.value;
                      this.changeInputState(atendimentoPesquisa, "atendimentoPesquisa");
                    }}
                    disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                    inputValue={this.state.atendimento.atendimentoPesquisa.detalhes}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>,

      /* Etapa - Descritivo */
      <Grid container className={"step"}>
        <Grid item xs={12} md={9} lg={6} xl={5}>
          <Grid container>
            {/* Titulo do projeto */}
            <Grid item xs={12}>
              <FormField
                type={"text"}
                label={`Título do ${this.linhaAtendimentoOptions[this.state.linhaAtendimento] == "PDI" ? "projeto" : "atendimento"}`}
                inputValue={this.state.atendimento.titulo}
                required={true}
                error={this.state.errors.titulo.error}
                maxLength={"500"}
                changeValue={(e) => {
                  this.changeInputState(e, "titulo");
                }}
                disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
              />
            </Grid>
            {/* Datas prevista Inicio e término*/}
            <Grid item xs={12}>
              <br />
              <label className="label-field">Datas Previstas</label>
              <br />
              <br />
            </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
              <Grid item xs={1} style={{ marginTop: 5 }}>
                <label className="label-field">Início</label>
              </Grid>
              <Grid item xs={5} className={"paddingForm"}>
                <FormField
                  type={"date"}
                  inputValue={this.state.atendimento.dataInicioPrevista != null && this.state.atendimento.dataInicioPrevista !== "" ? this.formatData(this.state.atendimento.dataInicioPrevista) : ""}
                  changeValue={(e) => {
                    this.changeInputState(e, "dataInicioPrevista");
                  }}
                  icon={"calendar.svg"}
                  error={this.state.errors.dataInicioPrevista.error}
                  required={true}
                  disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                />
              </Grid>

              <Grid item xs={1} style={{ marginTop: 5 }}>
                <label className="label-field">Fim</label>
              </Grid>

              <Grid item xs={5}>
                <FormField
                  type={"date"}
                  inputValue={
                    this.state.atendimento.dataConclusaoPrevista != null && this.state.atendimento.dataConclusaoPrevista !== "" ? this.formatData(this.state.atendimento.dataConclusaoPrevista) : ""
                  }
                  changeValue={(e) => {
                    this.changeInputState(e, "dataConclusaoPrevista");
                  }}
                  icon={"calendar.svg"}
                  required={true}
                  disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()}
                  error={this.state.errors.dataConclusaoPrevista.error}
                />
              </Grid>
            </Grid>

            {/* Área de serviço */}
            {this.linhaAtendimentoOptions[this.state.linhaAtendimento] == "PDI" && (
              <>
                <Grid item xs={12}>
                  {/* Possui Área de pesquisa */}
                  {this.state.atendimento.unidade && this.state.atendimento.unidade.id && (
                    <InputSelect
                      key="tipoProduto"
                      label={"Área de serviço (Opcional)"}
                      suggestions={areaServicoOptions[this.state.atendimento.unidade.id] || []}
                      initialSelectedItem={this.state.atendimento.areaServico}
                      getInput={(e) => this.changeInputState(e, "areaServico")}
                      disabled={areaServicoOptions[this.state.atendimento.unidade.id] === undefined}
                    />
                  )}
                </Grid>

                {/* Area de pesquisa */}
                <Grid item xs={12} className={"areaPesquisa"}>
                  <Grid item xs={12}>
                    <label className="label-field">Área de pesquisa (selecione até 3)</label>
                  </Grid>
                  <AutocompleteMult
                    multiple
                    options={areaPesquisaSuggestions}
                    groupBy={(option) => option.group}
                    getOptionLabel={(option) => option.descricao}
                    onChange={this.onTagsChange}
                    disabled={this.state.isAreaPesquisaMaxOptions}
                    value={this.state.atendimento.areaPesquisaList}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                  <div className="areaPesquisaSelectedTags">
                    {this.state.atendimento.areaPesquisaList.map((tag) => (
                      <p className={"tags"} key={tag.descricao}>
                        {tag.descricao}
                        <span onClick={() => this.removeTag(tag)}>
                          <img style={{ width: 10 }} src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`}></img>
                        </span>
                      </p>
                    ))}
                  </div>
                </Grid>
              </>
            )}

            {/* Escopo */}
            <Grid item xs={12}>
              {(this.isUO || this.isDR) && this.linhaAtendimentoOptions[this.state.linhaAtendimento] === "PDI" ? (
                <FormField
                  type={"textarea"}
                  label={`Escopo`}
                  changeValue={(e) => this.changeInputState(e, "resumoExecutivo")}
                  inputValue={this.state.atendimento.resumoExecutivo}
                  maxLength={"500"}
                  disabled={this.checkStatusAlterarStep2() || !this.checkUnidadeAlterar()}
                />
              ) : (
                <FormField
                  type={"textarea"}
                  label={"Escopo (Opcional)"}
                  changeValue={(e) => this.changeInputState(e, "resumoExecutivo")}
                  inputValue={this.state.atendimento.resumoExecutivo}
                  maxLength={"1000"}
                  disabled={this.checkStatusAlterarStep2() || !this.checkUnidadeAlterar()}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>,

      /* Etapa - Organização/Pessoa Atendida */
      <Grid container className={"step"}>
        <Grid item xs={12} md={9} lg={6} xl={5}>
          <Grid container>
            <Grid item xs={12}>
              <AutoComplete
                label={"Selecione uma organização/pessoa já cadastrada"}
                path={"rest/pessoa/Cliente/0/30?isAtivo=true&buscaLivre="}
                itemKey={"id"}
                disabled={this.checkStatusAlterarStep2() || !this.checkUnidadeAlterar()}
                key="autoCompleteClientes"
                initialSelectedItem={this.state.atendimento.cliente ? this.state.atendimento.cliente : ""}
                itemlabel={"nome"}
                defaultObject={true}
                placeholder={"Busque pelo nome, CPF, CNPJ ou ID"}
                itemTemplate={this.createTemplateCliente}
                getInput={(e) => this.changeInputState(e, "cliente")}
              />

              {this.state.isParceiroRede ? <span className={"cliente-tip"}>O cliente deve ser a unidade executora do atendimento em rede.</span> : null}
            </Grid>
            {this.state.atendimento.cliente.id && !this.state.novoCliente.usuario ? (
              <div className={"review-info"}>
                <Grid>
                  {this.state.atendimento.cliente ? (
                    <Grid item xs={12}>
                      <section>
                        <h6>{this.state.atendimento.cliente.nome}</h6>
                        <span>
                          <GenericButton
                            color={"transparent"}
                            click={(e) => {
                              let atendimento = this.state.atendimento;
                              atendimento.setor = { id: 0, descricao: "" };
                              this.setState({ setoresPorCnaeObjects: [], atendimento: atendimento });
                              this.setState((prevState) => ({ atendimento: { ...prevState.atendimento, cliente: { razaoSocial: "", cpfcnpj: "", enderecoList: [] } } }));
                            }}
                            subClass={"icon-and-button"}
                          />
                        </span>
                      </section>
                      <p>{this.state.atendimento.cliente.razaoSocial}</p>
                      {this.state.atendimento.cliente.cpfcnpj ? <p>{Masks.maskByTipoPessoa(this.state.atendimento.cliente.cpfcnpj, 1)}</p> : ""}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </div>
            ) : null}

            {this.state.novoCliente.usuario && this.state.novoCliente.usuario.id ? (
              <div className={"review-info"}>
                <Grid>
                  {this.state.novoCliente.razaoSocial || this.state.novoCliente.nome ? (
                    <Grid item xs={12}>
                      <section>
                        <h6>{this.state.novoCliente.nome}</h6>
                        <span>
                          <GenericButton
                            color={"transparent"}
                            click={(e) => {
                              this.setState({ novoCliente: {} });
                            }}
                            subClass={"icon-and-button"}
                            icon={"close.svg"}
                            iconWidth={12}
                            iconHeight={12}
                          />
                        </span>
                      </section>
                      <p>{this.state.novoCliente.razaoSocial}</p>
                      {this.state.novoCliente.cpfcnpj ? (
                        <p>
                          {Masks.maskByTipoPessoa(this.state.atendimento.cliente.cpfcnpj, 1)} - {this.state.novoCliente.enderecoList[0].municipio.descricao}
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </div>
            ) : null}
            <Grid item xs={12} style={{ paddingBottom: "15px", paddingTop: "15px" }}>
              <GenericButton label={"Adicionar organização/pessoa"} color={"darkBlue"} subClass={"basic-button"} click={(e) => this.setState({ openModal: true })} />
            </Grid>

            {this.state.isAtendimentoBMPSemPorte && <AlertBrasilMaisProdutivo cliente={this.state.atendimento.cliente} atendimento={true} />}
            {this.state.emOutroAtendimentoBMP && <AlertAtendimentoBMP cliente={this.state.atendimento.cliente} produtoRegional={this.state.atendimento.produtoRegional} />}

            <Grid item xs={12}>
              <br />
              <br />
              <InputSelect
                key="setorIndustrial"
                label={"Setor atendido (Baseado nos CNAEs)"}
                suggestions={this.state.setoresPorCnaeObjects}
                disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || !this.state.setoresPorCnaeObjects || this.state.setoresPorCnaeObjects.length === 0}
                initialSelectedItem={{ id: this.state.atendimento.setor.id }}
                getInput={(e) => this.changeInputState(e, "setor")}
                itemKey={"id"}
                itemLabel={"descricao"}
              ></InputSelect>
            </Grid>
          </Grid>
        </Grid>
      </Grid>,

      /* Etapa - Recurso  */
      <Grid container className={"step"}>
        <Grid item xs={12} md={9} lg={7}>
          <Grid className={"padding-radio"} item xs={12}>
            <label className="label-field">Adicione a(s) fonte(s) de recursos</label>
            <Grid item xs={12} style={{ paddingBottom: "15px", paddingTop: "15px" }}>
              <GenericButton
                label={"Adicionar fonte"}
                color={"darkBlue"}
                subClass={"basic-button"}
                click={() => {
                  this.setState({ optionModalFonte: "Adicionar", openModalFonte: true });
                }}
              />
            </Grid>
          </Grid>

          <SimpleModal
            isOpen={this.state.openModalFonte}
            disableClick={true}
            handleClose={() => {
              this.setState({ openModalFonte: false });
              this.clearModalAddFonte();
            }}
            type={"info"}
            className={"modal-fonte"}
          >
            <h2>{`${this.state.optionModalFonte} fonte de recursos`}</h2>
            <Grid container className={"container-modal-fonte"}>
              {/* Tipo de fonte pagadora */}
              <Grid className={"padding-radio"} item xs={12}>
                <label className="label-field">Tipo de fonte pagadora</label>
                <Grid>
                  <RadioGroup row className={"radio-group-fonte"} value={this.state.radioTipoFontePagadora} onChange={this.changeRadioValueTipoFontePagadora}>
                    <FormControlLabel className={"radio"} value={"mercado"} control={<Radio />} label={"Mercado"} />
                    <FormControlLabel className={"radio"} value={"sistema-indústria"} control={<Radio />} label={"Sistema indústria"} />
                    <FormControlLabel className={"radio"} value={"fomento-externo"} control={<Radio />} label={"Fomento externo"} />
                  </RadioGroup>
                </Grid>
              </Grid>
              {this.state.radioTipoFontePagadora !== "" && (
                <Grid item xs={12}>
                  {this.state.radioTipoFontePagadora === "mercado" && (
                    <Grid item xs={12}>
                      <Grid className={"padding-radio"} item xs={12}>
                        <label className="label-field">É uma ICT?</label>
                        <Grid>
                          <RadioGroup row className={"radio-group"} value={this.state.radioIsICT} onChange={this.validateIsICT}>
                            <FormControlLabel className={"radio"} value={"true"} control={<Radio />} label={"Sim"} />
                            <FormControlLabel className={"radio"} value={""} control={<Radio />} label={"Não"} />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {this.state.radioTipoFontePagadora === "sistema-indústria" && (
                    <Grid item xs={12}>
                      <Grid className={"padding-radio"} item xs={12}>
                        <label className="label-field">Tipo de fonte</label>
                        <Grid>
                          <RadioGroup row className={"radio-group"} value={this.state.radioTipoFonte} onChange={this.validateTipoFonte}>
                            <FormControlLabel className={"radio"} value={"uo-atendimento"} control={<Radio />} label={"UO de atendimento"} />
                            <FormControlLabel className={"radio"} value={"uo-parceira"} control={<Radio />} label={`UO's Parceiras`} />
                            <FormControlLabel className={"radio"} value={"dr-atendimento"} control={<Radio />} label={"DR do atendimento"} />
                            <FormControlLabel className={"radio"} value={"sesi-senai-dn"} control={<Radio />} label={"SESI/SENAI DN"} />
                            <FormControlLabel className={"radio"} value={"outros-sistemas"} control={<Radio />} label={"Outros (Sistema Indústria)"} />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <label className="label-field">Selecione uma entidade</label>
                    <Grid className={"input-entidade"}>
                      <Grid item xs={12}>
                        <AutoComplete
                          itemKey={"id"}
                          key="autoCompleteFonte"
                          disabled={this.canSetAutoCompleteFonte() || (this.state.radioTipoFontePagadora === "sistema-indústria" && this.state.radioTipoFonte === "")}
                          path={this.state.autoCompleteFontePath}
                          posPath={"%"}
                          itemlabel={this.switchTemplateFontePagadora(false)}
                          initialSelectedItem={this.state.fontePagadora ? this.state.fontePagadora : ""}
                          itemTemplate={this.switchTemplateFontePagadora()}
                          getInput={(e) => this.changeInputState(e, "fontePagadora")}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Financeiro e economico */}
                  <Grid item xs={12} className={"align-finance-economic"}>
                    <label className="label-field">Financeiro</label>
                    {this.state.radioTipoFontePagadora === "sistema-indústria" &&
                    this.state.radioTipoFonte !== "outros-sistemas" &&
                    !["STE", "Serviços Complementares"].includes(this.linhaAtendimentoOptions[this.state.linhaAtendimento]) &&
                    this.state.radioTipoFonte !== "sesi-senai-dn" ? (
                      <FormField disabled={true} placeholder={"0,00"} style={{ width: "300px" }} type={"number"} min="0" />
                    ) : (
                      <FormField
                        placeholder={"R$"}
                        type={"number"}
                        id={"financeiro"}
                        changeValue={(e) => this.changeValoresFontes(e)}
                        min="0"
                        maxLength="15"
                        subtype={"duascasas"}
                        inputValue={this.state.valorFonteFinanceiro}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} className={"align-finance-economic"}>
                    <label className="label-field">Econômico</label>
                    {this.state.radioTipoFontePagadora === "fomento-externo" || ["STE", "Serviços Complementares"].includes(this.linhaAtendimentoOptions[this.state.linhaAtendimento]) ? (
                      <FormField disabled={true} placeholder={"0,00"} style={{ width: "300px" }} type={"number"} min="0" />
                    ) : (
                      <FormField
                        placeholder={"R$"}
                        type={"number"}
                        changeValue={(e) => this.changeValoresFontes(e)}
                        min="0"
                        maxLength="15"
                        subtype={"duascasas"}
                        inputValue={this.state.valorFonteEconomico}
                      />
                    )}
                  </Grid>

                  {/* Número do projeto no SGF */}
                  {this.state.radioTipoFontePagadora === "sistema-indústria" && this.state.radioTipoFonte === "sesi-senai-dn" && (
                    <Grid item xs={12} style={{ marginTop: 32 }}>
                      <label className="label-field">Número do projeto no SGF</label>
                      <Grid item xs={12} style={{ marginTop: 8 }}>
                        <FormField
                          placeholder={"Insira o número do projeto no SGF"}
                          type="number"
                          subtype="inteiro"
                          changeValue={(e) => this.changeInputState(e, "numeroProjetoSGF")}
                          inputValue={this.state.atendimento.numeroProjetoSGF}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {/* Faz recursos regulados ou de isenção fiscal */}
                  {this.state.radioTipoFontePagadora === "mercado" && (
                    <Grid className={"padding-radio"} item xs={12}>
                      <label className="label-field"> Faz uso de recursos regulados ou de isenção fiscal?</label>
                      <Grid>
                        <RadioGroup row className={"radio-group"} value={this.state.radioIsReguladosIsento} onChange={this.validateIsReguladoIsento}>
                          <FormControlLabel className={"radio"} value={""} control={<Radio disabled={this.state.radioIsICT === "true"} />} label={"Não"} />
                          <FormControlLabel className={"radio"} value={"true"} control={<Radio disabled={this.state.radioIsICT === "true"} />} label={"Sim"} />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  )}
                  {this.state.radioIsReguladosIsento !== "" && this.state.radioTipoFontePagadora === "mercado" && (
                    <Grid item xs={12}>
                      <label className="label-field"> Informe qual recurso:</label>
                      <Grid className={"input-entidade"}>
                        <Grid item xs={12}>
                          <AutoComplete
                            itemKey={"id"}
                            key="autoCompleteNomeRecurso"
                            path={"/rest/gestao/AgenciaNacional?descricao=%"}
                            posPath={"%"}
                            itemlabel={"descricao"}
                            initialSelectedItem={this.state.recursoRegulado ? this.state.recursoRegulado : ""}
                            getInput={(e) => this.changeInputState(e, "recursoRegulado")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "100px", position: "relative" }} item xs={12}>
                    <GenericButton
                      color={"darkGrey-outline"}
                      label={"Cancelar"}
                      subClass={"basic-button"}
                      click={() => {
                        this.setState({ openModalFonte: false });
                        this.clearModalAddFonte();
                      }}
                    />
                    <GenericButton label={this.state.optionModalFonte} color={"darkBlue"} subClass={"basic-button"} disabled={this.cantAdicionarFontes()} click={this.createEditFonteRecurso} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </SimpleModal>

          {this.state.fontesRecursos.length > 0 && (
            <Grid container style={{ marginBottom: "10px" }}>
              <Grid className="total-de-recursos" item xs={12}>
                <p>Fontes de recursos</p>
                <p>Valor do recurso</p>
              </Grid>
            </Grid>
          )}

          {/* Mostrar adicionados */}

          {this.state.fontesRecursos.map((fontesRecurso, i) => {
            return (
              <>
                <Grid className="review-de-recursos" item xs={12} key={i}>
                  <Grid className={"display-fonte"}>
                    <Grid className={"count-fonte"}>
                      <p className="count"> {i < 10 ? `0${i + 1}` : i + 1} |</p>
                      <p>
                        {this.getDescricaoFonte(fontesRecurso)
                          .toLowerCase()
                          .split("-")
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(" ")}
                      </p>
                    </Grid>
                    <b>
                      {fontesRecurso.sistemaIndustria
                        ? `${fontesRecurso.sistemaIndustria.cpfcnpj} - ${fontesRecurso.sistemaIndustria.nome}`
                        : fontesRecurso.agenciaFomento
                        ? fontesRecurso.agenciaFomento.descricao
                        : fontesRecurso.fontePagadora
                        ? `${fontesRecurso.fontePagadora.cpfcnpj} - ${fontesRecurso.fontePagadora.nome}`
                        : fontesRecurso.ictParceiro && fontesRecurso.ictParceiro.descricao
                        ? fontesRecurso.ictParceiro.descricao
                        : fontesRecurso.unidade.descricao}
                    </b>
                  </Grid>
                  <Grid className={"display-total"}>
                    <p className="total">{(fontesRecurso.valor + fontesRecurso.economico).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</p>
                    <label>
                      <img
                        className={"icon-atend"}
                        src={process.env.PUBLIC_URL + "/assets/icons/pen.svg"}
                        icon={"pen.svg"}
                        alt="icon"
                        onClick={() => {
                          this.setState({ optionModalFonte: "Editar" });
                          this.fillEditFonteRecurso(fontesRecurso);
                        }}
                      />
                    </label>
                    <label>
                      <img className={"icon-atend"} src={process.env.PUBLIC_URL + "/assets/icons/trash.svg"} icon={"trash.svg"} alt="icon" onClick={() => this.removerFonteRecurso(fontesRecurso)} />
                    </label>
                  </Grid>
                </Grid>
                <p className={"review-border"}></p>
              </>
            );
          })}

          {this.state.fontesRecursos.length > 0 && (
            <Grid className="total-de-recursos" item xs={12}>
              <h5>Total de recursos</h5>
              <h5>
                {this.state.fontesRecursos
                  .reduce(function (prev, curr) {
                    return prev + ((parseFloat(curr.economico) || 0) + (parseFloat(curr.valor) || 0));
                  }, 0)
                  .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
              </h5>
            </Grid>
          )}

          {/* <Grid container className={'recursos-container'} spacing={2}>
                        <Grid item xs={6} sm={3} style={{ paddingBottom: '15px' }}>
                            <div style={{ display: 'flex' }}>
                                <GenericButton label={'Mercado'} color={'darkBlue'} subClass={'basic-button'} click={(e) => { }}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA ||
                                        this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                                <GenericButton color={'lightBlue'} subClass={'icon-button'} click={(e) => this.verifyLEAN(1)} icon={'plus.svg'}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA ||
                                        this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} style={{ paddingBottom: '15px' }}>
                            <div style={{ display: 'flex' }}>
                                <GenericButton label={'ICT'} color={'darkBlue'} subClass={'basic-button'} click={(e) => { }}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA ||
                                        this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                                <GenericButton color={'lightBlue'} subClass={'icon-button'} click={(e) => {
                                    let cont = this.addFonteICT(this.getReceita(5), this.getIdPanel());
                                    this.addPrevisaoItemReceita(this.getReceita(5), cont, cont.id)
                                }} icon={'plus.svg'}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA ||
                                        this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} style={{ paddingBottom: '15px' }}>
                            <div style={{ display: 'flex' }}>
                                <GenericButton label={'Agência Nacional'} color={'darkBlue'} subClass={'basic-button'} click={(e) => { }}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA ||
                                        this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                                <GenericButton color={'lightBlue'} subClass={'icon-button'} click={(e) => {
                                    let cont = this.addFonteAgenciaNacional(this.getReceita(7), this.getIdPanel());
                                    this.addPrevisaoItemReceita(this.getReceita(7), cont, cont.id)
                                }} icon={'plus.svg'}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA ||
                                        this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} style={{ paddingBottom: '15px' }}>
                            <div style={{ display: 'flex' }}>
                                <GenericButton label={'Agência de Fomento'} color={'darkBlue'} subClass={'basic-button'} click={(e) => { }}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA ||
                                        this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                                <GenericButton color={'lightBlue'} subClass={'icon-button'} click={(e) => {
                                    let cont = this.addFonteAgenciaFomento(this.getReceita(8), this.getIdPanel());
                                    this.addPrevisaoItemReceita(this.getReceita(8), cont, cont.id)
                                }} icon={'plus.svg'}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA ||
                                        this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                            </div>
                        </Grid>
                    </Grid> */}

          {/*
                    <Grid container className={'recursos-container'} spacing={2}>
                        <Grid item xs={6} sm={3} style={{ paddingBottom: '15px' }}>
                            <div style={{ display: 'flex' }}>
                                <GenericButton label={'UO do Atendimento'} color={'darkBlue'} subClass={'basic-button'} click={(e) => { }}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA ||
                                        this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                                <GenericButton color={'lightBlue'} subClass={'icon-button'} click={(e) => {
                                    let cont = this.addUOAtendimento(this.getReceita(10), this.getIdPanel());
                                    this.addPrevisaoItemReceita(this.getReceita(10), cont, cont.id)
                                }} icon={'plus.svg'}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA ||
                                        this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} style={{ paddingBottom: '15px' }}>
                            <div style={{ display: 'flex' }}>
                                <GenericButton label={'DR do Atendimento'} color={'darkBlue'} subClass={'basic-button'} click={(e) => { }}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoIA || this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN ||
                                        this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                                <GenericButton color={'lightBlue'} subClass={'icon-button'} click={(e) => this.verifyLEAN(11)} icon={'plus.svg'}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoIA || this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN ||
                                        this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} style={{ paddingBottom: '15px' }}>
                            <div style={{ display: 'flex' }}>
                                <GenericButton label={'Senai Parceiro'} color={'darkBlue'} subClass={'basic-button'} click={(e) => { }}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA
                                        || this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                                <GenericButton color={'lightBlue'} subClass={'icon-button'} click={(e) => {
                                    let cont = this.addSenaiParceiro(this.getReceita(12), this.getIdPanel());
                                    this.addPrevisaoItemReceita(this.getReceita(12), cont, cont.id)
                                }} icon={'plus.svg'}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA ||
                                        this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} style={{ paddingBottom: '15px' }}>
                            <div style={{ display: 'flex' }}>
                                <GenericButton label={'Sistema Indústria'} color={'darkBlue'} subClass={'basic-button'} click={(e) => { }}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA ||
                                        this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                                <GenericButton color={'lightBlue'} subClass={'icon-button'} click={(e) => {
                                    let cont = this.addSistemaIndustria(this.getReceita(13), this.getIdPanel());
                                    this.addPrevisaoItemReceita(this.getReceita(13), cont, cont.id)
                                }} icon={'plus.svg'}
                                    disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.isAtendimentoLEAN() || this.isAtendimentoIA ||
                                        this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL} />
                            </div>
                        </Grid>
                    </Grid> */}
        </Grid>
      </Grid>,

      /* Etapa Produção */
      <Grid container className={"step"}>
        <Grid item xs={12} md={9} lg={6} xl={5}>
          {/* Produção */}
          {!this.isMetrologia && (
            <Grid container>
              {/*  <Grid container spacing={5} direction="row" justify="flex-start" alignItems="center">
                            <Grid item xs={6} ><FormField type={'number'} label={'Produção total prevista (em horas)'} changeValue={(e) => {
                                this.changeInputState(e.target.value, 'numeroDeProducaoEstimada');
                                this.calcularSomaReceitaProducao();
                            }} inputValue={this.state.atendimento.numeroDeProducaoEstimada !== '' ? this.state.atendimento.numeroDeProducaoEstimada : 0} min='1' subtype={'inteiro'} disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() ||
                                this.state.isParceiroRede || this.state.atendimento.isEscopoIndefinido || this.state.atendimento.isValorHora || this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE ||
                                this.isAccepted || this.isAtendimentoIA180 || this.isAtendimentoDIGITAL || this.isAtendimentoLEAN() || this.isAtendimentoIA} />
                            </Grid>

                            {!(this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted ||
                                this.isAtendimentoIA180 || this.isAtendimentoDIGITAL || this.isAtendimentoLEAN() || this.isAtendimentoIA || this.state.atendimento.isValorHora) && <Grid item xs={6} >
                                    <FormControlLabel className='producaoAlign' value="end" control={<Checkbox defaultChecked={this.state.atendimento.isEscopoIndefinido}
                                        onChange={(e) => { this.changeInputState(e.target.checked, 'isEscopoIndefinido'); this.clearProducao(); }} style={{ color: '#077CDC' }} />}
                                        label="Produção indefinida" labelPlacement="end" disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()
                                            || this.state.isParceiroRede || this.state.atendimento.isValorHora} />
                                </Grid>}
                        </Grid>*/}

              {/* Botão de produção indefinida */}
              {!(
                this.isAtendimentoBP ||
                this.isAtendimentoBrasilMaisLEAN ||
                this.isAtendimentoIPEA ||
                this.isAtendimentoIE ||
                this.isAccepted ||
                this.isAtendimentoIA180 ||
                this.isAtendimentoDIGITAL ||
                this.isAtendimentoLEAN() ||
                this.isAtendimentoIA ||
                this.state.isAtendimentoBMP ||
                this.state.atendimento.isValorHora
              ) && (
                <Grid item xs={6}>
                  <FormControlLabel
                    className="producaoAlign"
                    value="end"
                    control={
                      <Checkbox
                        defaultChecked={this.state.atendimento.isEscopoIndefinido}
                        onChange={(e) => {
                          this.changeInputEscopoIndefinido();
                          this.clearProducao();
                        }}
                        style={{ color: "#077CDC" }}
                      />
                    }
                    label="A produção ainda não foi definida"
                    labelPlacement="end"
                    disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar() || this.state.isParceiroRede || this.state.atendimento.isValorHora}
                  />
                </Grid>
              )}

              {/* Botões de seleção de parceiro */}
              {/*  {!(this.state.isParceiroRede || this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted ||
                            this.isAtendimentoIA180 || this.isAtendimentoDIGITAL || this.isAtendimentoLEAN() || this.isAtendimentoIA || this.state.atendimento.isValorHora) && <Grid item xs={12}>
                                <label className='label-field'>Adicionar Parceiro</label>
                            </Grid>}
                        {!(this.state.isParceiroRede || this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN || this.isAtendimentoIPEA || this.isAtendimentoIE || this.isAccepted ||
                            this.isAtendimentoIA180 || this.isAtendimentoDIGITAL || this.isAtendimentoLEAN() || this.isAtendimentoIA || this.state.atendimento.isValorHora) && <Grid container className={'recursos-container'} spacing={2}>
                                <Grid item xs={2} style={{ marginTop: 8 }}>
                                    <div style={{ display: 'flex' }}>
                                        <GenericButton label={'Senai'} color={'darkBlue'} subClass={'basic-button'} click={(e) => this.setState({ addParceiro: 3, descricaoParceiro: '' })}
                                            disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()} />
                                        <GenericButton color={'lightBlue'} subClass={'icon-button'} click={(e) => this.setState({ addParceiro: 3, descricaoParceiro: '' })} icon={'plus.svg'}
                                            disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()} />
                                    </div>
                                </Grid>
                                <Grid item xs={2} style={{ marginTop: 8 }}>
                                    <div style={{ display: 'flex' }}>
                                        <GenericButton label={'ICT'} color={'darkBlue'} subClass={'basic-button'} click={(e) => this.setState({ addParceiro: 5, descricaoParceiro: '' })}
                                            disabled={this.state.isParceiroRede || this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()} />
                                        <GenericButton color={'lightBlue'} subClass={'icon-button'} click={(e) => this.setState({ addParceiro: 5, descricaoParceiro: '' })} icon={'plus.svg'}
                                            disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()} />
                                    </div>
                                </Grid>
                                <Grid item xs={4} sm={3} style={{ marginTop: 8 }}>
                                    <div style={{ display: 'flex' }}>
                                        <GenericButton label={'Agência de Bolsas'} color={'darkBlue'} subClass={'basic-button'} click={(e) => this.setState({ addParceiro: 9, descricaoParceiro: '' })}
                                            disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()} />
                                        <GenericButton color={'lightBlue'} subClass={'icon-button'} click={(e) => this.setState({ addParceiro: 9, descricaoParceiro: '' })} icon={'plus.svg'}
                                            disabled={this.checkStatusAlterarStep1() || !this.checkUnidadeAlterar()} />
                                    </div>
                                </Grid>
                            </Grid>}  */}

              <SimpleModal
                isOpen={this.state.openModalParceria}
                disableClick={true}
                handleClose={() => this.setState({ openModalParceria: false, radioTipoParceria: "" })}
                type={"info"}
                className={"modal-parceria"}
              >
                <h2>{"Adicionar Parceria"}</h2>

                <Grid container className={"container-modal-parceria"}>
                  <Grid className={"padding-radio"} item xs={12}>
                    <label className="label-field">Tipo de parceria</label>
                    <Grid>
                      <RadioGroup row className={"radio-group-parceria"} value={this.state.radioTipoParceria} onChange={this.changeRadioValueTipoParceria}>
                        <FormControlLabel className={"radio"} value={"senai"} control={<Radio />} label={"SENAI"} />
                        <FormControlLabel className={"radio"} value={"ict"} control={<Radio />} label={"ICT"} />
                      </RadioGroup>
                    </Grid>
                  </Grid>

                  <Grid className={"padding-radio"} item xs={12}>
                    {this.state.radioTipoParceria === "senai" && (
                      <>
                        <label className="label-field">Nome do parceiro</label>
                        <AutoComplete
                          placeholder={"Busque pelo nome"}
                          itemKey={"id"}
                          defaultObject={true}
                          itemlabel={"abreviacao"}
                          path={"/rest/gestao/Unidade/0/30?tipoUnidade.sigla=UO&buscaLivre="}
                          initialSelectedItem={{ abreviacao: "" }}
                          getInput={(e) => {}}
                          itemTemplate={this.createTemplateUnidade}
                          key="autoCompleteAgenciaNacional"
                          getInputButton={(e) => {
                            this.addParceiro(e);
                          }}
                          button={true}
                        />
                      </>
                    )}

                    {this.state.radioTipoParceria === "ict" && (
                      <>
                        <label className="label-field">Nome do parceiro</label>
                        <AutoComplete
                          placeholder={"Digite o ict parceiro"}
                          itemKey={"id"}
                          button={true}
                          defaultObject={true}
                          itemlabel={"descricao"}
                          path={"/rest/gestao/IctParceiro?descricao=%"}
                          posPath={"%"}
                          key="autoCompleteIctParceiro"
                          getInput={(e) => {
                            this.setState({ parceiroICTAgencia: e });
                          }}
                          initialSelectedItem={this.state.parceiroICTAgencia}
                          getInputButton={(e) => this.addParceiro(e)}
                        />
                      </>
                    )}
                  </Grid>

                  <Table lines={this.getLinesTable(true)} titles={this.titlesTabelaList} pagination={false} initialOrder={"9"} />

                  <Grid className={"container-modal-parceria"}>
                    <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "100px", position: "relative" }} item xs={12}>
                      <GenericButton color={"darkGrey-outline"} label={"Cancelar"} subClass={"basic-button"} click={() => this.setState({ openModalParceria: false, radioTipoParceria: "" })} />
                      <GenericButton label={"Concluir"} color={"darkBlue"} subClass={"basic-button"} click={this.closeParceiroModal} />
                    </Grid>
                  </Grid>
                </Grid>
              </SimpleModal>
              {/*
                        {(this.state.addParceiro === 3) &&
                            <Grid item xs={12}>
                                <AutoComplete placeholder={'Digite a agência de fomento'} itemKey={'id'} defaultObject={true}
                                    itemlabel={'abreviacao'} path={'/rest/gestao/Unidade/0/30?tipoUnidade.sigla=UO&buscaLivre='}
                                    initialSelectedItem={{ abreviacao: '' }} getInput={(e) => { }}
                                    itemTemplate={this.createTemplateUnidade}
                                    key='autoCompleteAgenciaNacional' getInputButton={(e) => { this.addParceiro(e) }} button={true} />
                            </Grid>}

                        {(this.state.addParceiro === 5) && <Grid container spacing={2}>
                            <Grid item xs={6} >
                                <AutoComplete placeholder={'Digite o ict parceiro'} itemKey={'id'} button={true} defaultObject={true}
                                    itemlabel={'descricao'} path={'/rest/gestao/IctParceiro?descricao=%'} posPath={'%'}
                                    key='autoCompleteIctParceiro' getInput={(e) => { this.setState({ parceiroICTAgencia: e }) }}
                                    initialSelectedItem={this.state.parceiroICTAgencia} getInputButton={(e) => this.addParceiro(e)} />
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: 18 }}>
                                <FormField type={'text'} changeValue={(e) => this.setState({ descricaoParceiro: e.target.value })} inputValue={this.state.descricaoParceiro} />
                            </Grid>
                        </Grid>}

                        {(this.state.addParceiro === 9) && <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <AutoComplete placeholder={'Digite a agência de bolsas'} itemKey={'id'} button={true} defaultObject={true}
                                    path={'/rest/gestao/AgenciaBolsa?descricao=%'} posPath={'%'} itemlabel={'descricao'}
                                    getInput={(e) => { this.setState({ parceiroICTAgencia: e }) }}
                                    key='autoCompleteAgenteBolsas' getInputButton={(e) => { this.addParceiro(e) }}
                                    initialSelectedItem={this.state.parceiroICTAgencia} />
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: 18 }}>
                                <FormField type={'text'} changeValue={(e) => this.setState({ descricaoParceiro: e.target.value })} inputValue={this.state.descricaoParceiro} />
                            </Grid>
                        </Grid>
                        } */}

              {/* Tabela com todas as parcerias */}
              {!this.state.isParceiroRede && (
                <Grid item xs={12}>
                  {/* O indice de ordem deve ser inexistente pois essa tabela não deve ser ordenada */}
                  <Table lines={this.getLinesTable()} titles={this.titlesTabela} pagination={false} initialOrder={"9"} />
                </Grid>
              )}
              <Grid className={"container-parceria"}>
                <Grid item xs={4} style={{ paddingBottom: "15px", paddingTop: "15px" }}>
                  <GenericButton label={"Adicionar parceria"} color={"darkBlue"} subClass={"basic-button"} click={(e) => this.setState({ openModalParceria: true })} />
                </Grid>
                <Grid item xs={3} className={"review-producao"}>
                  <b>Produção total prevista</b>
                  <p>{this.state.producaoTotalPrevista} h</p>
                </Grid>
                <Grid item xs={3} className={"review-producao"}>
                  <b>Receita total prevista</b>
                  <p>
                    {this.state.fontesRecursos
                      .reduce(function (prev, curr) {
                        return prev + (parseFloat(curr.valor) || 0);
                      }, 0)
                      .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                  </p>
                </Grid>
              </Grid>
              {!this.state.producaoBMPConforme && this.state.isAtendimentoBMP && (
                <AlertProducaoBMP
                  cliente={this.state.atendimento.cliente}
                  produtoRegional={this.state.atendimento.produtoRegional}
                  horas={this.producaoPorPorte[this.state.atendimento.cliente?.porteCliente?.id]?.[this.state.atendimento.produtoRegional.produtoNacional.id]} 
                  porte={this.state.atendimento.cliente?.porteCliente?.descricao}
                />
              )}
            </Grid>
          )}

          {this.isMetrologia && (
            <Grid container direction="row" alignItems="center">
              {/* <FormControlLabel className='producaoAlign' value="end" control={<Checkbox defaultChecked={this.state.atendimento.isEscopoIndefinido}
                            onChange={(e) => {
                                this.changeInputState(e.target.checked, 'isEscopoIndefinido'); this.changeInputState(0, 'numeroDeRelatorioEstimado');
                                this.changeInputState(0, 'numeroDeProducaoEstimada');
                            }} style={{ color: '#077CDC' }} />}
                            label="Produção indefinida" labelPlacement="end" disabled={this.state.isParceiroRede || this.state.atendimento.isValorHora} />*/}

              <Grid item xs={2}>
                <p>
                  <strong>Categoria:</strong>
                </p>
              </Grid>

              <Grid item xs={10}>
                <p style={{ marginLeft: -32 }}>{this.state.atendimento.produtoRegional.produtoNacional.produtoCategoria.descricao}</p>
              </Grid>

              <Grid container alignItems="center" className="producao-header-grid" spacing={2}>
                <Grid item xs={6}>
                  <p>Nome da unidade</p>
                </Grid>

                <Grid item xs={3}>
                  <p>{this.getTextoProducaoPrevista()}</p>
                </Grid>

                <Grid item xs={3}>
                  <p>{this.getTextoNumeroRelatorio()}</p>
                </Grid>
              </Grid>

              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={6}>
                  <p>{this.state.atendimento.unidade.descricao}</p>
                </Grid>

                <Grid item xs={3}>
                  <FormField
                    type={"number"}
                    inputValue={this.state.atendimento.numeroDeProducaoEstimada}
                    changeValue={(e) => {
                      this.changeInputState(e, "numeroDeProducaoEstimada");
                    }}
                    disabled={this.state.atendimento.isEscopoIndefinido}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormField
                    type={"number"}
                    inputValue={this.state.atendimento.numeroDeRelatorioEstimado}
                    changeValue={(e) => {
                      this.changeInputState(e, "numeroDeRelatorioEstimado");
                    }}
                    disabled={this.state.atendimento.isEscopoIndefinido}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>,

      /* Revisão Geral */
      <Grid container className={"step review-step"}>
        <Grid className={"review-div"} key={1} container direction="column" justify="center" alignItems="center">
          <div className={"review-info-atendimento"}>
            <Grid container>
              {/* Review Informações Gerais */}
              <Grid item xs={1}>
                <div style={{ backgroundColor: "#077CDC" }} className={"review-square"}>
                  <label>1</label>
                </div>
              </Grid>

              <Grid style={{ fontWeight: "700", fontSize: "14pt", marginLeft: "-3%", marginTop: "2px" }} item xs={11}>
                <label>Informações gerais</label>
              </Grid>

              <Grid className={"container-review"}>
                {/* Informações */}
                <Grid className={"layout-review"}>
                  <Grid className={"layout-review"}>
                    <b>Unidade Operacional</b>
                    <p>{this.state.atendimento.unidade.descricao}</p>
                  </Grid>
                </Grid>

                <Grid className={"layout-review"}>
                  <Grid className={"layout-review"}>
                    <b>Departamento Regional:</b>
                    <p>{this.state.atendimento.unidade.unidadePai.abreviacao}</p>
                  </Grid>
                </Grid>

                <Grid className={"layout-review"}>
                  <Grid className={"layout-review"}>
                    <b>Produto Regional</b>
                    <p>{this.state.atendimento.produtoRegional.nome}</p>
                  </Grid>
                </Grid>

                <Grid className={"layout-review"}>
                  <Grid className={"layout-review"}>
                    <b> Produto Nacional</b>
                    <p>{this.state.atendimento.produtoRegional.produtoNacional.descricao}</p>
                  </Grid>
                </Grid>

                <Grid className={"layout-review"}>
                  <Grid className={"layout-review"}>
                    <b> Linha </b>
                    <p>{this.linhaAtendimentoOptions[this.state.linhaAtendimento]}</p>
                  </Grid>
                </Grid>
              </Grid>

              {/* Review Descritivo */}
              <Grid item xs={1}>
                <div style={{ backgroundColor: "#077CDC" }} className={"review-square"}>
                  <label>2</label>
                </div>
              </Grid>
              <Grid style={{ fontWeight: "700", fontSize: "14pt", marginLeft: "-3%", marginTop: "2px" }} item xs={11}>
                <label>Descritivo</label>
              </Grid>

              <Grid className={"container-review"}>
                <Grid className={"layout-review"}>
                  <Grid className={"layout-review"}>
                    <b>Título do Atendimento</b>
                    <p>{this.state.atendimento.titulo}</p>
                  </Grid>
                </Grid>

                <Grid className={"layout-review"}>
                  <Grid className={"layout-review"}>
                    <b>Datas previstas</b>
                    <p>
                      Início: {this.formatDataCheckout(this.state.atendimento.dataInicioPrevista)} Fim: {this.formatDataCheckout(this.state.atendimento.dataConclusaoPrevista)}
                    </p>
                  </Grid>
                </Grid>

                {this.linhaAtendimentoOptions[this.state.linhaAtendimento] != "Consultoria" && (this.state.atendimento.areaServico != null ? this.state.atendimento.areaServico : "").length != 0 && (
                  <Grid className={"layout-review"}>
                    <Grid className={"layout-review"}>
                      <b>Área de serviço</b>
                      <p>{this.state.atendimento.areaServico}</p>
                    </Grid>
                  </Grid>
                )}

                {this.linhaAtendimentoOptions[this.state.linhaAtendimento] != "Consultoria" && (this.state.atendimento.areaServico != null ? this.state.atendimento.areaServico : "").length != 0 && (
                  <Grid className={"layout-review"}>
                    <Grid className={"layout-review"}>
                      <b>Linha de pesquisa</b>
                      <p>{this.state.atendimento.produtoRegional.nome}</p>
                    </Grid>
                  </Grid>
                )}

                {this.state.atendimento.resumoExecutivo != "" && (
                  <Grid className={"layout-review"}>
                    <Grid className={"layout-review"}>
                      <b>Escopo</b>
                      <p>{this.state.atendimento.resumoExecutivo}</p>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* Review Pessoa/Organização atendida */}
              <Grid item xs={1}>
                <div style={{ backgroundColor: "#077CDC" }} className={"review-square"}>
                  <label>3</label>
                </div>
              </Grid>
              <Grid style={{ fontWeight: "700", fontSize: "14pt", marginLeft: "-3%", marginTop: "2px" }} item xs={11}>
                <label> Pessoa/Organização atendida</label>
              </Grid>

              <Grid className={"container-review"}>
                <Grid className={"layout-review"}>
                  <Grid className={"layout-review"}>
                    <b>Organização</b>
                    <p>{this.state.atendimento.cliente.razaoSocial}</p>
                  </Grid>
                </Grid>

                <Grid className={"layout-review"}>
                  <Grid className={"layout-review"}>
                    <b>CNPJ</b>
                    <p>{Masks.maskByTipoPessoa(this.state.atendimento.cliente.cpfcnpj, 1)}</p>
                  </Grid>
                </Grid>

                <Grid className={"layout-review"}>
                  <Grid className={"layout-review"}>
                    <b>Setor Industrial</b>
                    <p>{this.state.atendimento.setor.descricao}</p>
                  </Grid>
                </Grid>
              </Grid>

              {/* Recursos */}
              <Grid item xs={1}>
                <div style={{ backgroundColor: "#077CDC" }} className={"review-square"}>
                  <label>4</label>
                </div>
              </Grid>
              <Grid style={{ fontWeight: "700", fontSize: "14pt", marginLeft: "-3%", marginTop: "2px" }} item xs={11}>
                <label>Recursos</label>
              </Grid>

              {/* {this.state.previsaoReceitaList.map((prevReceita, index) => {

                                if (prevReceita.tipoPrevisaoReceita) {
                                    return (
                                        <Grid container key={index}>
                                            <Grid item xs={9}> <div><label>0{index + 1} Fonte Pagadora | {tipoPrevisaoReceitaOptions[prevReceita.tipoPrevisaoReceita.id]}</label><br />
                                                <label>{this.getDescricaoFonte(prevReceita)}</label>
                                            </div>
                                            </Grid>
                                            <Grid item xs={3}>R$ {this.getSumFontePagadora(prevReceita).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Grid>
                                        </Grid>
                                    )
                                }
                                return (
                                    <div></div>
                                )
                            })} */}
              <Grid className={"container-review"} style={{ marginLeft: "46px" }}>
                {this.state.fontesRecursos.map((fontesRecurso, i) => {
                  return (
                    <>
                      <Grid className="review-de-recursos" item xs={12}>
                        <Grid className={"display-fonte"}>
                          <Grid className={"count-fonte-review"}>
                            <p className="count"> {i < 10 ? `0${i + 1}` : i + 1}</p>
                            <p>
                              {" "}
                              |{" "}
                              {this.getDescricaoFonte(fontesRecurso)
                                .toLowerCase()
                                .split("-")
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ")}
                            </p>
                          </Grid>
                          <b>
                            {fontesRecurso.sistemaIndustria
                              ? `${fontesRecurso.sistemaIndustria.cpfcnpj} - ${fontesRecurso.sistemaIndustria.nome}`
                              : fontesRecurso.agenciaFomento
                              ? fontesRecurso.agenciaFomento.descricao
                              : fontesRecurso.fontePagadora
                              ? `${fontesRecurso.fontePagadora.cpfcnpj} - ${fontesRecurso.fontePagadora.nome}`
                              : fontesRecurso.ictParceiro && fontesRecurso.ictParceiro.descricao
                              ? fontesRecurso.ictParceiro.descricao
                              : fontesRecurso.unidade.descricao}
                          </b>
                        </Grid>
                        <Grid className={"display-total-review"}>
                          <p className="total-review"> R$ {(fontesRecurso.valor + fontesRecurso.economico).toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
                <Grid className="review-de-recursos" item xs={12}>
                  <Grid className={"display-fonte"}>
                    <h5>Valor total financeiro</h5>
                  </Grid>
                  <Grid className={"display-total-review"}>
                    <p className="total-review">
                      R$ {this.state.fontesRecursos.reduce((acc, current) => acc + +current.valor, 0).toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                    </p>
                  </Grid>
                </Grid>
                <Grid className="review-de-recursos" item xs={12}>
                  <Grid className={"display-fonte"}>
                    <h5>Valor total econômico</h5>
                  </Grid>
                  <Grid className={"display-total-review"}>
                    <p className="total-review">
                      R$ {this.state.fontesRecursos.reduce((acc, current) => acc + +current.economico, 0).toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                    </p>
                  </Grid>
                </Grid>
                <Grid className="review-de-recursos" item xs={12}>
                  <Grid className={"display-fonte"}>
                    <h5>Valor total do projeto</h5>
                  </Grid>
                  <Grid className={"display-total-review"}>
                    <p className="total-review">
                      R${" "}
                      {this.state.fontesRecursos
                        .reduce((acc, current) => acc + +current.economico + +current.valor, 0)
                        .toLocaleString("pt-BR", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                    </p>
                  </Grid>
                </Grid>
              </Grid>

              {/* Produção */}
              <Grid item xs={1}>
                <div style={{ backgroundColor: "#077CDC" }} className={"review-square"}>
                  <label>5</label>
                </div>
              </Grid>
              <Grid style={{ fontWeight: "700", fontSize: "14pt", marginLeft: "-3%", marginTop: "2px" }} item xs={11}>
                <label>Produção</label>
              </Grid>

              {/*  {this.isMetrologia ?
                                <Grid item xs={3}>Ensaios previstos</Grid>
                                :
                                <Grid item xs={3}>Horas previstas</Grid>}
                            {this.isMetrologia ?
                                <Grid item xs={3}>Relatórios/laudos previstos</Grid>
                                :
                                 null} */}

              {/*  <Grid item xs={3}>Receita prevista</Grid> */}

              {this.isMetrologia ? (
                <Grid container direction="row" alignItems="center" className={"producao-review-atendimento-novo"}>
                  {/* <FormControlLabel className='producaoAlign' value="end" control={<Checkbox defaultChecked={this.state.atendimento.isEscopoIndefinido}
                                        onChange={(e) => {
                                            this.changeInputState(e.target.checked, 'isEscopoIndefinido'); this.changeInputState(0, 'numeroDeRelatorioEstimado');
                                            this.changeInputState(0, 'numeroDeProducaoEstimada');
                                        }} style={{ color: '#077CDC' }} />}
                                        label="Produção indefinida" labelPlacement="end" disabled={this.state.isParceiroRede || this.state.atendimento.isValorHora} />*/}

                  <Grid item xs={6}>
                    <b>Total</b>
                  </Grid>

                  <Grid item xs={3}>
                    <b>{this.getTextoProducaoPrevista()}</b>
                  </Grid>
                  <Grid item xs={3}>
                    <b>{this.getTextoNumeroRelatorio()}</b>
                  </Grid>

                  <Grid item xs={6}>
                    <p>{this.state.atendimento.unidade.descricao}</p>
                  </Grid>

                  <Grid item xs={3}>
                    {this.state.atendimento.numeroDeProducaoEstimada}
                  </Grid>
                  <Grid item xs={3}>
                    {this.state.atendimento.numeroDeRelatorioEstimado}
                  </Grid>
                </Grid>
              ) : (
                <Grid className={"container-review"} style={{ marginLeft: "46px" }}>
                  {this.state.parceiroList.map((parc, index) => {
                    if (parc instanceof PrevisaoProducaoEmRedeModel) {
                      return (
                        <Grid className="review-de-recursos" item xs={12} key={`${index}`}>
                          <Grid className={"display-fonte horas"}>
                            <h4>{parc.unidade.descricao}</h4>
                            <h4>{parc.numeroDeProducaoEstimada.toLocaleString("pt-BR")} horas</h4>
                          </Grid>
                          <Grid className={"display-total-review"}>
                            <p className="total-review">R$ {parc.numeroDeReceitaEstimada.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                          </Grid>
                        </Grid>
                      );
                    }
                    if (parc instanceof PrevisaoReceitaModel && parc.tipoPrevisaoReceita.id === 3) {
                      return (
                        <Grid className="review-de-recursos" item xs={12} key={`${index}`}>
                          <Grid className={"display-fonte horas"}>
                            <h4>{parc.unidade.descricao}</h4>
                            <h4>{parc.numeroDeProducaoEstimada.toLocaleString("pt-BR")} horas</h4>
                          </Grid>
                          <Grid className={"display-total-review"}>
                            <p className="total-review">R$ {parc.numeroDeReceitaEstimada.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                          </Grid>
                        </Grid>
                      );
                    }
                    if (parc instanceof PrevisaoReceitaModel && parc.tipoPrevisaoReceita.id === 5) {
                      return (
                        <Grid className="review-de-recursos" item xs={12} key={`${index}`}>
                          <Grid className={"display-fonte horas"}>
                            <h4>{parc.ictParceiro.descricao}</h4>
                            <h4>0 horas</h4>
                          </Grid>
                          <Grid className={"display-total-review"}>
                            <p className="total-review">R$ {parc.receitarepassada.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 })} </p>
                          </Grid>
                        </Grid>
                      );
                    }
                    if (parc instanceof PrevisaoReceitaModel && parc.tipoPrevisaoReceita.id === 9) {
                      return (
                        <Grid className="review-de-recursos" item xs={12} key={`${index}`}>
                          <Grid className={"display-fonte horas"}>
                            <h4>{parc.agenciaBolsa.descricao}</h4>
                            <h4>0 horas</h4>
                          </Grid>
                          <Grid className={"display-total-review"}>
                            <p className="total-review">R$ {parc.receitarepassada.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 })} </p>
                          </Grid>
                        </Grid>
                      );
                    }
                    return <div></div>;
                  })}
                  <Grid className="review-de-recursos" item xs={12}>
                    <Grid className={"display-fonte horas"}>
                      <h4>
                        <b>Total</b>
                      </h4>
                      <h4>
                        <b>{this.state.atendimento.numeroDeProducaoEstimada} horas</b>
                      </h4>
                    </Grid>
                    <Grid className={"display-total-review"}>
                      <p className="total-review">R$ {this.state.atendimento.numeroDeReceitaEstimada.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>,
    ];
  }

  createTemplateStatus = (status) => {
    return (
      <div className={"input-select-options change"}>
        <div>
          <label className={`${status.chave}`}>{status.descricao}</label>
        </div>
      </div>
    );
  };

  changeDataAceitacao = (e) => {
    const today = new Date();
    today.setHours(23);
    
    if (e != null && e !== "") {
        const dateString = e.target.value + 'T21:00:00-03:00';
        const dateNegociacao = new Date(this.state.atendimento.dataNegociacao);
        let dateAssinatura = new Date(e.target.value);

        dateAssinatura.setDate(dateAssinatura.getDate() + 1);

      if (this.state.isAtendimentoBMP) {
        const differenceInDays = (today.setHours(0, 0, 0, 0) - dateAssinatura.setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24);

        if (differenceInDays > 30) {
            this.dataAceitacao = e.target.value;
            this.canChangeStatus = false;
            this.errors.dataAceitacao.error = 'A data de assinatura não pode ser superior a 30 dias retroativos para atendimentos BMP.';
            this.errors.dataAceitacao.subClass = 'isInvalid';
            this.setState(this.state);
            return;
        }
    }

      if (this.formatData(dateString) <= this.formatData(today) && this.formatData(dateString) >= this.formatData(dateNegociacao)) {
        this.dataAceitacao = e.target.value;
        this.errors.dataAceitacao.error = "";
        this.errors.dataAceitacao.subClass = "";
        this.canChangeStatus = true;
      } else {
        this.dataAceitacao = e.target.value;
        this.canChangeStatus = false;
        this.errors.dataAceitacao.error = "A data de aceitação deve estar entre a data de negociação e a data atual.";
        this.errors.dataAceitacao.subClass = "isInvalid";
      }
    }

    this.setState(this.state);
  };


  render() {
    return (
      <Page icon={"showcase.svg"} label={"Novo Atendimento"} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
        <Grid container className={"novo-atendimento"}>
          <Grid className={"pagination-gerenciar-atendimento"}>
            <Link to={"/cad/atendimento/atendimento"}>
              <div className={"return-gerenciar-atendimento"}>
                <ArrowBackIosIcon style={{ color: "#077CDC", fontSize: "28px" }} />
                <p>Gerenciar atendimentos</p>
              </div>
            </Link>
            <ArrowForwardIosIcon style={{ color: "darkGrey", fontSize: "14px", marginRight: "10px" }} />
            <p style={{ color: "#077CDC" }}>Cadastro de atendimento</p>
          </Grid>

          <Stepper
            titles={this.titles}
            steps={this.getSteps()}
            reset={"Atendimento criado com sucesso!"}
            step={this.getStep}
            sendForm={
              this.linhaAtendimentoOptions[this.state.linhaAtendimento] == "PDI" || this.isAtendimentoDIGITAL || this.props.idAtendimento
                ? this.salvarAtendimento
                : () => this.setState({ openModalConfirmaStatusInicial: true })
            }
            validation={this.validateAtendimento}
            history={this.props.history}
          />
        </Grid>
        <SimpleModal className={"modal-atendimento-cliente"} isOpen={this.state.openModal} disableClick={true} handleClose={() => this.setState({ openModal: false })} type={"info"} width={"1000px"}>
          <NovoClientePage
            usuarioConectado={this.props.usuarioConectado}
            asModal={true}
            showLoading={this.showLoading}
            closeLoading={this.closeLoading}
            showToast={this.showToast}
            closeModal={this.closeModalCliente}
            updateCliente={this.salvarNovoCliente}
          />
        </SimpleModal>

        <SimpleModal
          isOpen={this.state.openModalConfirmaStatusInicial}
          disableClick={true}
          handleClose={() => this.setState({ openModalConfirmaStatusInicial: false })}
          type={"info"}
          className={"modal-confirmacao"}
        >
          <h2>{"Confirmar o status inicial"}</h2>
          <Grid container className={"container-modal-confirmacao"}>
            <Grid className={"padding-radio"} item xs={12}>
              <div style={{ width: "100%" }}>
                <InputSelect
                  suggestions={this.statusList}
                  itemKey={"id"}
                  itemLabel={"descricao"}
                  itemTemplate={this.createTemplateStatus}
                  id={"atendimentoStatus"}
                  initialSelectedItem={{ id: this.state.atendimentoStatus }}
                  getInput={(e) => {
                    this.setState({ atendimentoStatus: e.id });
                  }}
                />
              </div>
            </Grid>

            {this.state.atendimentoStatus > 2 && (
              <Grid className={"status-fields"} item xs={12}>
                <Grid item>
                  <FormField
                    type={"text"}
                    label={"Descrição/Número da proposta regional"}
                    changeValue={(e) => this.setState({ descricaoProposta: e.target.value })}
                    inputValue={this.state.descricaoProposta}
                  />
                </Grid>
                <Grid item>
                  <label className="label-field">Anexar proposta regional</label>
                  <div className="button-wrapper">
                    <span className="label">
                      Selecionar arquivo <CloudUploadIcon />
                    </span>
                    <input
                      type="file"
                      name="upload"
                      id="upload"
                      className="upload-box"
                      onChange={(e) => this.setState({ nomeArquivoProposta: e.target.value, uploadAnexoProposta: e.target.files[0] })}
                    />
                  </div>
                  <p className="file-name">{this.state.nomeArquivoProposta.split("\\").pop()}</p>
                </Grid>
              </Grid>
            )}

            {this.state.atendimentoStatus == 4 && (
              <Grid className={"status-fields"} item xs={12}>
                <Grid item>
                  <FormField
                    type={"date"}
                    ref="dataAceitacao"
                    id={"dataAceitacao"}
                    inputValue={this.formatData(this.state.dataAssinaturaContrato)}
                    changeValue={this.changeDataAceitacao}
                    icon={"calendar.svg"}
                    label={"Data de assinatura do instrumento jurídico"}
                  />
                  <span className={"font-red"}>{this.errors.dataAceitacao.error}</span>
                </Grid>
                <Grid item>
                  <label className="label-field">Anexar instrumento jurídico</label>
                  <div className="button-wrapper">
                    <span className="label">
                      Selecionar arquivo <CloudUploadIcon />
                    </span>
                    <input
                      type="file"
                      name="upload"
                      id="upload"
                      className="upload-box"
                      onChange={(e) => this.setState({ nomeArquivoContrato: e.target.value, uploadAnexoContrato: e.target.files[0] })}
                    />
                  </div>
                  <p className="file-name">{this.state.nomeArquivoContrato.split("\\").pop()}</p>
                </Grid>
              </Grid>
            )}
            <Grid className={"container-modal-confirmacao"}>
              <Grid style={{ display: "flex", justifyContent: "space-between", marginTop: "100px", position: "relative" }} item xs={12}>
                <GenericButton color={"darkGrey-outline"} label={"Cancelar"} subClass={"basic-button"} click={() => this.setState({ openModalConfirmaStatusInicial: false })} />
                <GenericButton
                  label={"Confirmar"}
                  color={"darkBlue"}
                  subClass={"basic-button"}
                  click={this.salvarAtendimento}
                  disabled={
                    (this.state.atendimentoStatus == 3 && (this.state.descricaoProposta == "" || this.state.nomeArquivoProposta == "")) ||
                    (this.state.atendimentoStatus == 4 && (this.state.descricaoProposta == "" || this.state.nomeArquivoProposta == "" || this.state.nomeArquivoContrato == "" || !this.canChangeStatus))
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </SimpleModal>
      </Page>
    );
  }
}
